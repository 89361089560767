//React
import React from 'react';
//Component
const Home = () => {
  return (
    <div className="content-wrapper">
      <h1>Home</h1>
    </div>
  )
}

export default Home;
