//React
import React from 'react';
import { BrowserRouter, Routes , Route } from 'react-router-dom';
//Routes
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PriveteRoute';
import CourseRoute from './routes/CourseRoute';
//Public pages
import Login from './components/publicPages/Login';
import Register from './components/publicPages/Register';
import ForgotPassword from './components/publicPages/ForgotPassword';
import RecoverPassword from './components/publicPages/RecoverPassword';
import AuthenticateEmail from './components/publicPages/AuthenticateEmail';
//private pages
import Home from './components/privatePages/Home';
import Courses from './components/privatePages/Courses';
import Users from './components/privatePages/Users';
import UserEditAdd from './components/privatePages/UserEditAdd';
import CourseEditAdd from './components/privatePages/CoursesEditAdd';
import TopicsEditAdd from './components/privatePages/TopicsEditAdd';
import Saved from './components/privatePages/Saved';
import Quiz from './components/privatePages/Quiz';
//profile
import Profile from './components/privatePages/Profile';
//Elements Page
//Elements Page - Profile
import Studying from './components/elements/profile/Studying';
//import Follow from './components/elements/profile/Follow';
import Followers from './components/elements/profile/Followers';
import Following from './components/elements/profile/Following';
import ListUser from './components/elements/profile/ListUser';
import MyCourses from './components/elements/profile/MyCourses';

import ViewTeacher from './components/elements/ViewTeacher';
import Students from './components/elements/myCourse/Students';
import Student from './components/elements/myCourse/Student';
import QuizQuestions from './components/elements/myCourse/QuizQuestions';
//profile edit
import ProfileEdit from './components/privatePages/ProfileEdit';
//Elements Page
import PersonalData from './components/elements/profile/edit/PersonalData'

import NotFound from './components/NotFound';

const App = () => {
  return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={ <PublicRoute /> }>
					<Route path='/' element={ <Login />} />
					<Route path='/register' element={ <Register />} />
					<Route path='/forgotPassword' element={ <ForgotPassword />} />
					<Route path='/recoverPassword/:email' element={ <RecoverPassword />} />
					<Route path='/authenticateEmail/:email' element={ <AuthenticateEmail />} />
				</Route>
				<Route path='/private' element={ <PrivateRoute /> }>
							<Route path='/private/:user_id/home' element={ <Home /> } />
							<Route path='/private/:user_id/courses' element={ <Courses /> } />
							<Route path='/private/:user_id/users/:name' element={ <Users /> } />
							<Route path='/private/:user_id/profile/:userId' element={ <Profile />}>
								<Route path='/private/:user_id/profile/:userId' element={ <Studying /> } />
								<Route path='/private/:user_id/profile/:userId/:type_user/:sector' element={ <ListUser /> } />
								<Route path='/private/:user_id/profile/:userId/viewTeacher/:teacher_id' element={ <ViewTeacher /> } />
								{/* <Route path='/private/:user_id/profile/:userId/:type_page' element={ <Follow /> } /> */}
								<Route path='/private/:user_id/profile/:userId/followers' element={ <Followers /> } />
								<Route path='/private/:user_id/profile/:userId/following' element={ <Following /> } />
								<Route path='/private/:user_id/profile/:userId/coursesTeacher' element={ <MyCourses /> } />
								<Route path='/private/:user_id/profile/:userId/:courseName/students/:course_id' element={ <Students /> } />
								<Route path='/private/:user_id/profile/:userId/:courseName/student/:course_id/:userId/:name' element={ <Student /> } />
								<Route path='/private/:user_id/profile/:userId/:courseName/student/:course_id/:userId/:studying_id/quizQuestions/:quiz_id/:dateStart' element={ <QuizQuestions /> } />
							</Route>
							<Route path='/private/:user_id/profile/edit/:userId' element={ <ProfileEdit /> }>
								<Route path='/private/:user_id/profile/edit/:userId' element={ <PersonalData /> }/>
							</Route>

							<Route path='/private/:user_id/addUser/:typeUser/:sector' element={ <UserEditAdd /> } />
							<Route path='/private/:user_id/editUser/:typeUser/:userId/:sector' element={ <UserEditAdd />} />

							<Route path='/private/:user_id/addCourse' element={ <CourseEditAdd /> } />
							<Route path='/private/:user_id/editCourse/:course_id' element={ <CourseEditAdd /> } />

							<Route path='/private/:user_id/editCourse/:course_id/topics' element={ <TopicsEditAdd /> } />

							<Route path='/private/saved' element={ <Saved /> } />
				</Route>
				<Route path='/private/:user_id/course/:course_id' element={ <CourseRoute /> } />
				<Route path='/private/:user_id/course/:course_id/:studying_id/topics/:topic_id/quiz/:quiz_id/:amount' element={ <Quiz /> } />
				<Route path='*' element={ <NotFound />} />
			</Routes>
		</BrowserRouter>
  )
}

export default App;
