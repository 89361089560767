//React
import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
//Utils
import { getApiKey  } from '../../utils/config';
import { alert  } from '../../utils/alerts';
//Component
const AuthenticateEmail = () => {
	const { email } = useParams();

	const [token, setToken] = useState();

	function submitEmail(e){
		e.preventDefault()

		const requestOptions = {
			method: 'POST',
			headers: new Headers({
				"Content-Type": "application/json"
			}),
			mode: 'cors',
			body: JSON.stringify({
				email: email,
				token
			})
		}

		fetch(`${getApiKey()}/user/authenticateEmail`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				if(data.code === 10){
					alert("success", data.message);
					setTimeout(() => window.location.href = `/`, 1500)
				} else if(data.code === 20){
					alert("error", data.message);
				}
			})
		})
		.catch( response => {
			response.json().then( data => {
				alert("error", data.message)
			})
		})
	}

	return (
				<div className="login-box">
						<div className="card card-outline card-primary">
								<div className="card-header text-center">
										<Link to="/"><img className="contaider_logo" src="https://audaces.com/wp-content/themes/Audaces-2018/img/logo.svg" alt="Logo Audaces"/></Link>
								</div>
								<div className="card-body">
										<p className="login-box-msg">O código foi enviado por email, verifique seu inbox.</p>
										<form onSubmit={(e) => submitEmail(e)}>
												<div className="input-group mb-3">
														<input type="text" className="form-control" required placeholder="Insira o codigo" onChange={(e) => setToken(e.target.value)}/>
														<div className="input-group-append">
																<div className="input-group-text">
																		<span className="fas fa-qrcode"></span>
																</div>
														</div>
												</div>
												<div className="row">
														<div className="col-12">
																<button type="submit" className="btn btn-primary btn-block"> enviar </button>
														</div>
												</div>
										</form>
										<p className="mt-3 mb-1">
												<Link to="/">Login</Link>
										</p>
								</div>
						</div>
				</div>
	)
}
export default AuthenticateEmail;
