//React
import React, {useEffect, useState } from 'react';
import { useParams, Outlet , Navigate, Link  } from 'react-router-dom';
//Utils
import { isLogged, getToken  } from '../../utils/auth';
import { getApiKey  } from '../../utils/config';
//layout
import SubHeader from '../layout/SubHeader';
//Component
const Profile = () => {
	const { user_id } = useParams();
	const { userId } = useParams();

	const [user, setUser] = useState();
	const [isFollower, setIsFollower] = useState();

	const [follower, setFollower] = useState();
	const [following, setFollowing] = useState();

	const headers = new Headers({
		"Content-Type": "application/json",
		'x-access-token': getToken()
	});

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers
		}

		fetch(`${getApiKey()}/follow/getFollowerFollowing/?follower=${user_id}&following=${userId}`, requestOptions).then(response => {
			response.json()
			.then(data => {
				(!!data.follow) ? setIsFollower(false) : setIsFollower(true);
			})
		})

		fetch(`${getApiKey()}/follow/getAllFollowerFollowing/?user_id=${userId}`, requestOptions).then(response => {
			response.json()
			.then(data => {
				setFollower(data.follower)
				setFollowing(data.following)
			})
		})

		fetch(`${getApiKey()}/user/getById/?user_id=${userId}`, requestOptions).then(response => {
			response.json().then(data => setUser(data.user))
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, userId, isFollower])

	function follow(){
		const requestOptions = {
			method: 'POST',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				follower: user_id,
				following: userId
			})
		};
		fetch(`${getApiKey()}/follow/create`, requestOptions)
		.then(response => {
			setIsFollower(!isFollower)
		})
	}

	function unFollow(){
		const requestOptions = {
			method: 'DELETE',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				follower: userId,
				following: user_id
			})
		};
		fetch(`${getApiKey()}/follow/delete`, requestOptions)
		.then(response => {
			setIsFollower(!isFollower)
		})
	}

	if(!isLogged()) return <Navigate  to="/"/>;
	if(!user || !follower || !following) return <>Loading..</>;

	return(
		<div className="content-wrapper">
			<SubHeader name='Perfil' itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Perfil'}]}/>
			<section className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-3">
							<div className="card card-primary card-outline">
								<div className="card-body box-profile">
									<div className="text-center">
										<img className="profile-user-img img-fluid img-circle" src={user.profilePicture} alt="User profile" />
									</div>
									<h3 className="profile-username text-center">{user.name}</h3>
									<p className="text-muted text-center">{user.biography}</p>
									<ul className="list-group list-group-unbordered mb-3">
										<li className="list-group-item">
											<b>Seguidores</b> <Link to={`/private/${user_id}/profile/${userId}/followers`} className="float-right">{follower.length}</Link>
										</li>
										<li className="list-group-item">
											<b>Seguindo</b> <Link to={`/private/${user_id}/profile/${userId}/following`} className="float-right">{following.length}</Link>
										</li>
									</ul>
									{
										(user_id !== userId && isFollower) && <button type='button' onClick={()=>follow()} className="btn btn-primary btn-block"><b>Seguir</b></button>
									}{
										(user_id !== userId && !isFollower) && <button type='button' onClick={()=>unFollow()} className="btn btn-danger btn-block"><b>Deixar de Seguir</b></button>
									}
									{
										user_id === userId && <Link to={`/private/${user_id}/profile/edit/${userId}`} className="btn btn-secondary btn-block"><b>Editar</b></Link>
									}
								</div>
							</div>
						</div>
						<div className="col-md-9">
							<div className="card">
								<div className="card-header p-2">
									<ul className="nav nav-pills">
										{
											user_id === userId && <li className="nav-item"><Link to={`/private/${user_id}/profile/${userId}`} id='studying' className="nav-link" data-toggle="tab">Cursando</Link></li>
										}
										{ ( user_id === userId && user.type_user === "admin" ) &&
												<li className="nav-item"><Link to={`/private/${user_id}/profile/${userId}/subAdmin/${user.sector}`} id='admins' className="nav-link" data-toggle="tab">Administradores</Link></li>
										}
										{ ( user_id === userId && (user.type_user === "admin" || user.type_user === "subAdmin") ) &&
												<li className="nav-item"><Link to={`/private/${user_id}/profile/${userId}/teacher/${user.sector}`} id='teachers' className="nav-link" data-toggle="tab">Professores</Link></li>
										}
										{ ( user_id === userId && (user.type_user === "admin" || user.type_user === "subAdmin" || user.type_user === "teacher") ) &&
												<li className="nav-item"><Link to={`/private/${user_id}/profile/${userId}/coursesTeacher`} id='myCourses' className="nav-link" data-toggle="tab">Meus Cursos</Link></li>
										}
										<li className="nav-item"><Link to={`/private/${user_id}/profile/${userId}/followers`} id='followers' className="nav-link" data-toggle="tab">Seguidores</Link></li>
										<li className="nav-item"><Link to={`/private/${user_id}/profile/${userId}/following`} id='following' className="nav-link" data-toggle="tab">Seguindo</Link></li>
									</ul>
								</div>
								<div className="card-body p-0">
									<Outlet />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Profile;
