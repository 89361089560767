//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//Utils
import { getToken } from '../../../../utils/auth';
import { getApiKey } from '../../../../utils/config';
import { alert } from '../../../../utils/alerts';
//Component
const CourseItem = ({ userId, course }) => {
	const [Course, setCourse] = useState(course);
	const [isActive, setIsChange] = useState(false);

	let data = new Date(Course.date_update)
	let dataFormatada = (data.getDate() + "/" + ((data.getMonth() +1 )) + "/" + (data.getFullYear() ))

	function changeActive(){
		const requestOptions = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			},
			mode: 'cors',
			body: JSON.stringify({
				course_id: Course.course_id,
				isActive: !Course.isActive
			})
		}
		fetch(`${getApiKey()}/course/changeActive`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				alert("info", data.message);
				setIsChange(!isActive);
				setCourse(data.course);
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				console.log(response)
				alert("error", data.message);
			}
			)
		})
	}

	return (
		<tr>
			<td> <Link to={`/private/${userId}/profile/${userId}/${Course.name}/students/${Course.course_id}`}> {Course.name} </Link> </td>
			<td> {dataFormatada} </td>
			<td className='project-actions text-right'>
				{
					!!Course.isActive && <Link to={``} className='btn btn-primary btn-sm mx-1'> <i className='fas fa-book'> </i> View </Link>
				}{
					!Course.isActive &&  <Link to={`/private/${userId}/editCourse/${Course.course_id}`} className='btn btn-info btn-sm mx-1'> <i className='fas fa-pencil-alt'> </i> Edit </Link>
				}{
					!!Course.isActive
					? <button onClick={() => changeActive()} className='btn btn-danger btn-sm mx-1'> <i className='fas fa-times'> </i> Desativar </button>
					: <button onClick={() => changeActive()} className='btn btn-success btn-sm mx-1'> <i className="fas fa-check"></i> Ativar </button>
				}
			</td>
		</tr>
	)
}

export default CourseItem;
