//React
import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
//Utils
import { getApiKey } from '../../utils/config';
import { alert } from '../../utils/alerts';
//Component
const RecoverPassword = () => {
	const email = useParams();

	const [token, setToken] = useState();
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();

	function resetPassword(e){
		e.preventDefault()

		const headers = new Headers({
			"Content-Type": "application/json"
		});

		const requestOptions = {
			method: 'POST',
			headers: headers,
			mode: 'cors',
			body: JSON.stringify({
				email: email.email,
				token: token,
				password: password,
				confirmPassword: confirmPassword
			})
		}

		fetch(`${getApiKey()}/user/login/resetPassword`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				if(data.code === 10){
					alert("error", data.message);
				} else if (data.code === 20){
					alert("success", data.message);
					setTimeout(() => window.location.href = `/`, 3000)
				}
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				alert(data.message)
			})
		})
	}

	return (
		<div className="login-box">
				<div className="card card-outline card-primary">
						<div className="card-header text-center">
								<Link to="/RecoverPassword"><img className="contaider_logo" src="https://audaces.com/wp-content/themes/Audaces-2018/img/logo.svg" alt="Logo Audaces"/></Link>
						</div>
						<div className="card-body">
								<p className="login-box-msg">Você está a apenas um passo de sua nova senha, recupere sua senha agora.</p>
								<form onSubmit={(e) => resetPassword(e)}>
										<div className="input-group mb-3">
												<input type="text" className="form-control" required placeholder="Codigo"  onChange={(e) => setToken(e.target.value)}/>
												<div className="input-group-append">
														<div className="input-group-text">
																<span className="fas fa-lock"></span>
														</div>
												</div>
										</div>
										<div className="input-group mb-3">
												<input type="password" className="form-control" required placeholder="Senha" onChange={(e) => setPassword(e.target.value)}/>
												<div className="input-group-append">
														<div className="input-group-text">
																<span className="fas fa-lock"></span>
														</div>
												</div>
										</div>
										<div className="input-group mb-3">
												<input type="password" className="form-control" required placeholder="Confirmar Senha" onChange={(e) => setConfirmPassword(e.target.value)}/>
												<div className="input-group-append">
														<div className="input-group-text">
																<span className="fas fa-lock"></span>
														</div>
												</div>
										</div>
										<div className="row">
												<div className="col-12">
														<button type='submit' className="btn btn-primary btn-block"> redefinir senha </button>
												</div>
										</div>
								</form>
								<p className="mt-3 mb-1">
										<Link to="/">Login</Link>
								</p>
						</div>
				</div>
		</div>
	)
}

export default RecoverPassword;
