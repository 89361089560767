import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ user_id }) => {
	const navigate = useNavigate();

  const [name, setName] = useState();

	function search(e){
		e.preventDefault()
		navigate(`/private/${user_id}/users/${name}`)
	}

	return (
		<nav className="main-header navbar navbar-expand navbar-white navbar-light">
				<ul className="navbar-nav">
					<li className="nav-item">
							<Link to="#" className="nav-link" data-widget="pushmenu"><i className="fas fa-bars" /></Link>
					</li>
					<li className="nav-item d-none d-sm-inline-block">
							<Link to={`/private/${user_id}/home`} className="nav-link">Home</Link>
					</li>
					<li className="nav-item d-none d-sm-inline-block">
							<Link to={`/private/${user_id}/courses`} className="nav-link">Cursos</Link>
					</li>
				</ul>
				<form onSubmit={search} className="form-inline ml-3">
						<div className="input-group input-group-sm">
								<input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search"  onChange={(e) => setName(e.target.value)} />
								<div className="input-group-append">
										<button className="btn btn-navbar" type="submit">
												<i className="fas fa-search" />
										</button>
								</div>
						</div>
				</form>
				<ul className="navbar-nav ml-auto">
					<li className="nav-item dropdown">
							<Link to="#" className="nav-link" data-toggle="dropdown">
									<i className="far fa-bell" />
									<span className="badge badge-warning navbar-badge">15</span>
							</Link>
							<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
									<span className="dropdown-item dropdown-header">15 Notifications</span>
									<div className="dropdown-divider" />
									<Link to="#" className="dropdown-item">
											<i className="fas fa-envelope mr-2" /> 4 new messages
											<span className="float-right text-muted text-sm">3 mins</span>
									</Link>
									<div className="dropdown-divider" />
									<Link to="#" className="dropdown-item">
											<i className="fas fa-users mr-2" /> 8 friend requests
											<span className="float-right text-muted text-sm">12 hours</span>
									</Link>
									<div className="dropdown-divider" />
									<Link to="#" className="dropdown-item">
											<i className="fas fa-file mr-2" /> 3 new reports
											<span className="float-right text-muted text-sm">2 days</span>
									</Link>
									<div className="dropdown-divider" />
									<Link to="#" className="dropdown-item dropdown-footer">See All Notifications</Link>
							</div>
					</li>
				</ul>
		</nav>
	)
}

export default Header;
