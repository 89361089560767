//React
import React, { useState } from "react";
import { getToken } from '../../utils/auth';
import { getApiKey } from '../../utils/config';
// import { Link } from "react-router-dom";
//Component
const ModalTechnology = ({ isNewTech, setIsNewTech }) => {

	const [name, setName] = useState()

	function create(){
		if(!!name){
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				},
				body: JSON.stringify({
					name: name
				})
			}
			fetch(`${getApiKey()}/technology/create`, requestOptions)
			.then(response => {
				response.json()
				.then(data => {
					setIsNewTech(!isNewTech)
				})
			})
		}
	}

	return(
		<div className="modal fade" id="modal-default">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Questionario</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
								<label>Nome da Tecnologia</label>
								<input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)}/>
							</div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
							<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>{create()}}>Cadastrar</button>
            </div>
          </div>
        </div>
      </div>
	)
}

const ModalCourse = ({ link }) => {
	function quizRedirect(){
		window.location.href = link;
	}
	return(
		<div className="modal fade" id="modal-default">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Questionario</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Ao clicar no botão começar você iniciará o exame de proficiência e compreensão da aula.
								Esse exame é composto por perguntas de múltipla escolha, onde apenas uma opção é a correta.
								O tempo máximo para resolução do exame é de 12 minutos, após esse tempo o exame é submetido automaticamente.
								Alcançando o mínimo de 70% de perguntas corretas, você concluirá a aula.</p>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
							<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>{quizRedirect()}}>Começar</button>
            </div>
          </div>
        </div>
      </div>
	)
}

export { ModalTechnology, ModalCourse }
