import React, { useEffect, useState} from 'react'
import { useParams, Navigate } from 'react-router-dom'
//lists
import User from '../lists/User'
//utils
import { isLogged, getToken  } from '../../utils/auth'
import { getApiKey  } from '../../utils/config'
//layout
import SubHeader from '../layout/SubHeader'
const Users = () => {
	const { user_id } = useParams();
	const { name } = useParams();

  const [users, setUsers] = useState();

	useEffect(() => {
		if(!!name){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				}
			}
			fetch(`${getApiKey()}/user/getByName?name=${name}`, requestOptions)
			.then(response => {
				response.json()
				.then(data => {
					setUsers(data.ret)
				})
			})
		}
  }, [name])

	if(!isLogged()) return <Navigate  to="/"/>

	if(!users) return <>Loading...</>

	return(
		<div className="content-wrapper">
			<SubHeader name={`Usuarios(${users.length})`} itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Cursos'}]}/>
			<section className="content">
				<div className="card card-solid">
					<div className="card-body pb-0">
						<div className="row">
							{
								users.map((user, index)=>
								<User key={index} user_id={user_id} user={user} />
								)
							}
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Users
