//React
import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
//Layout
import SubHeader from '../layout/SubHeader'
//Elements
import CourseData from '../elements/courseEditAdd/CourseData';
//Utils
import { isLogged, getToken  } from '../../utils/auth';
import { getApiKey  } from '../../utils/config';
import { ModalTechnology } from '../layout/Modal';

const CourseEditAdd = () =>{
	const { user_id } = useParams();
	const { course_id } = useParams();

	const [course, setCourse] = useState();

	const [isNewTech, setIsNewTech] = useState(false);

	useEffect(() => {
		if(!!course_id){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				}
			}
			fetch(`${getApiKey()}/course/getById/?course_id=${course_id}`, requestOptions).then(response => {
				response.json().then(data => setCourse(data.course[0]))
			})
		} else{
			setCourse({
				name: '',
				description: '',
				level: 'basic',
				technology: '',
				time: ''
			})
		}
  }, [course_id])

	if(!isLogged()) return <Navigate  to="/"/>

	if(!course) return <> Loading... </>;

	return(
		<div className="hold-transition sidebar-mini">
			<ModalTechnology isNewTech={isNewTech} setIsNewTech={setIsNewTech} />
			<div className="wrapper">
				<div className="content-wrapper">
				{
					!!course_id
					? <SubHeader name='Editar Curso' itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Perfil', path: `/private/${user_id}/profile/${user_id}/coursesTeacher`}, {name:'Editar Curso'}]}/>
					: <SubHeader name='Adicionar Curso' itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Perfil', path: `/private/${user_id}/profile/${user_id}/coursesTeacher`}, {name:'Adicionar Curso'}]}/>
				}
				<div className="content">
					<div className="container-fluid">
						<div className="card card-primary card-outline">
							<CourseData user_id={user_id} course={course} setCourse={setCourse} isNewTech={isNewTech} />
						</div>
					</div>
				</div>
				</div>
			</div>
		</div>
	)
}

export default CourseEditAdd;
