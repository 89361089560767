//React
import React, { useEffect, useState } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
//Layout
import SubHeader from '../layout/SubHeader'
//Elements
import TopicData from '../elements/courseEditAdd/TopicData';
import QuizData from '../elements/courseEditAdd/QuizData';
import QuestionData from '../elements/courseEditAdd/QuestionData';
import ChoiceData from '../elements/courseEditAdd/ChoiceData';
//Utils
import { isLogged, getToken  } from '../../utils/auth';
import { getApiKey  } from '../../utils/config';

const TopicsEditAdd = () =>{
	const { user_id } = useParams();
	const { course_id } = useParams();

	const [topic_id, setTopic_id] = useState()
	const [topics, setTopics] = useState()
	const [lastTopic, setLastTopic] = useState()

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}
		fetch(`${getApiKey()}/topics/getIdByCourse/?course_id=${course_id}`, requestOptions).then(response => {
			response.json().then(data => setTopics(data.listTopics))
		})
  }, [course_id])

	function changeTopic(e, id){
		if(!!e) e.preventDefault();
		document.getElementById(id).classList.add('active');
		if(!!lastTopic) document.getElementById(lastTopic).classList.remove('active');
		setLastTopic(id);
	}

	function resetTopic(){
		setTopic_id(undefined);
		if(!!lastTopic) document.getElementById(lastTopic).classList.remove('active');
		setLastTopic();
	}

	const [quiz_id, setQuiz_id] = useState()
	const [listQuiz, setListQuiz] = useState([])
	const [lastQuiz, setLastQuiz] = useState()

	useEffect(() => {
		if(!!topic_id){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				}
			}
			fetch(`${getApiKey()}/quiz/getAllById/?topics_id=${topic_id}`, requestOptions)
			.then(response => {
				response.json().then(data => setListQuiz(data.listQuiz))
			})
		} else {
			setQuiz_id(undefined)
		}
  }, [topic_id])

	function changeQuiz(e, id){
		if(!!e) e.preventDefault();
		document.getElementById(id).classList.add('active');
		if(!!lastQuiz) document.getElementById(lastQuiz).classList.remove('active');
		setLastQuiz(id);
	}

	function resetQuiz(){
		setQuiz_id(undefined);
		if(!!lastQuiz) document.getElementById(lastQuiz).classList.remove('active');
		setLastQuiz();
	}

	const [question_id, setQuestion_id] = useState()
	const [questions, setQuestions] = useState([])
	const [lastQuestion, setLastQuestion] = useState()

	useEffect(() => {
		if(!!quiz_id){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				}
			}
			fetch(`${getApiKey()}/question/getAllById/?quiz_id=${quiz_id}`, requestOptions).then(response => {
				response.json().then(data => setQuestions(data.listQuestion))
			})
		} else {
			setQuestion_id(undefined)
		}
  }, [quiz_id])

	function changeQuestion(e, id){
		if(!!e) e.preventDefault();
		document.getElementById(id).classList.add('active');
		if(!!lastQuestion) document.getElementById(lastQuestion).classList.remove('active');
		setLastQuestion(id);
	}

	function resetQuestion(){
		setQuestion_id(undefined);
		if(!!lastQuestion) document.getElementById(lastQuestion).classList.remove('active');
		setLastQuestion();
	}

	const [choice_id, setChoice_id] = useState();
	const [choices, setChoices] = useState([]);
	const [lastChoice, setLastChoice] = useState();

	useEffect(() => {
		if(!!question_id){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				}
			}
			fetch(`${getApiKey()}/choice/getAllById/?question_id=${question_id}`, requestOptions).then(response => {
				response.json().then(data => setChoices(data.listChoice))
			})
		} else {
			setChoice_id(undefined)
		}
  }, [question_id])

	function changeChoice(e, id){
		if(!!e) e.preventDefault();
		document.getElementById(id).classList.add('active');
		if(!!lastChoice) document.getElementById(lastChoice).classList.remove('active');
		setLastChoice(id);
	}

	function resetChoice(){
		setChoice_id(undefined);
		if(!!document.getElementById(lastChoice)) document.getElementById(lastChoice).classList.remove('active');
		setLastChoice(undefined);
	}

	if(!isLogged()) return <Navigate  to="/"/>

	if(!topics) return <> Loading... </>;

	if(!listQuiz) return <> Loading... </>;

	if(!questions) return <> Loading... </>;

	return(
		<div className="hold-transition sidebar-mini">
			<div className="wrapper">
				<div className="content-wrapper">
				{
					!!course_id
					? <SubHeader name='Editar Curso' itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Perfil', path: `/private/${user_id}/profile/${user_id}/coursesTeacher`}, {name:'Editar Curso', path: `/private/${user_id}/editCourse/${course_id}`}, {name:'Editar Topicos'}]}/>
					: <SubHeader name='Adicionar Curso' itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Perfil', path: `/private/${user_id}/profile/${user_id}/coursesTeacher`}, {name:'Adicionar Curso', path: `/private/${user_id}/editCourse/${course_id}`}, {name:'Adicionar Topicos'}]}/>
				}
				<div className="content">
					<div className="container-fluid">
					{
						!!course_id &&
							topics.length !== 0 &&
							<div className="card card-primary card-outline">
								<div className="card-header fixed-top">
									<ul className="nav nav-pills">
										{
											topics?.map((topic, index) =>
												<li key={index} className="nav-item">
													<Link to="#" id={'topic'+index} className="nav-link"
													style={{border: "0px"}}
													onClick={(e) => { changeTopic(e, 'topic'+index); setTopic_id(topic.topics_id)}}>
														{topic.name}
													</Link>
												</li>
											)
										}
									</ul>
								</div>
							</div>
						}
						{
							!!course_id  &&
							<div className="card card-primary card-outline">
								<div className="card-body">
									<TopicData course_id={course_id} topic_id={topic_id} setTopic_id={setTopic_id} topics={topics} setTopics={setTopics} resetTopic={resetTopic} lastTopic={lastTopic} changeTopic={changeTopic} />
								</div>
							</div>
						}
						{
							!!topic_id &&
							<div className="card card-primary card-outline">
								{
								listQuiz.length !== 0 &&
								<div className="card-header p-2">
									<ul className="nav nav-pills">
										{
											listQuiz?.map((quiz, index) =>
												<li key={index} className="nav-item">
													<Link to="#" id={'quiz'+index} className="nav-link"
													style={{border: "0px"}}
													onClick={(e) => {changeQuiz(e, 'quiz'+index); setQuiz_id(quiz.quiz_id); resetQuestion()}}>
														{quiz.name}
													</Link>
												</li>
											)
										}
									</ul>
								</div>
								}
								<QuizData topic_id={topic_id} quiz_id={quiz_id} setQuiz_id={setQuiz_id} listQuiz={listQuiz} setListQuiz={setListQuiz} resetQuiz={resetQuiz} lastQuiz={lastQuiz} changeQuiz={changeQuiz} />
							</div>
						}
						{
							!!quiz_id &&
							<div className="card card-primary card-outline">
								{
								questions.length !== 0 &&
								<div className="card-header p-2">
									<ul className="nav nav-pills">
										{
											questions?.map((question, index) =>
												<li key={index} className="nav-item">
													<Link to="#" id={'question'+index} className="nav-link"
													style={{border: "0px"}}
													onClick={(e) => {changeQuestion(e, 'question'+index); setQuestion_id(question.question_id); resetChoice()}}>
														{index+1}º Questão
													</Link>
												</li>
											)
										}
									</ul>
								</div>
								}
								<QuestionData quiz_id={quiz_id} question_id={question_id} setQuestion_id={setQuestion_id} questions={questions} setQuestions={setQuestions} resetQuestion={resetQuestion} lastQuestion={lastQuestion} changeQuestion={changeQuestion} />
							</div>
						}
						{
							!!question_id &&
							<div className="card card-primary card-outline">
								{
								choices.length !== 0 &&
								<div className="card-header p-2">
									<ul className="nav nav-pills">
										{
											choices?.map((choice, index) =>
												<li key={index} className="nav-item">
													<Link to="#" id={'choice'+index} className="nav-link"
													style={{border: "0px"}}
													onClick={(e) => {changeChoice(e, 'choice'+index); setChoice_id(choice.choice_id)}}>
														{index+1}º Alternativa
													</Link>
												</li>
											)
										}
									</ul>
								</div>
								}
								<ChoiceData question_id={question_id} choice_id={choice_id} choices={choices} setChoices={setChoices} resetChoice={resetChoice} lastChoice={lastChoice}/>
							</div>
						}
					</div>
				</div>
				</div>
			</div>
		</div>
	)
}

export default TopicsEditAdd;
