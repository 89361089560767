 import React, { useState, useEffect } from 'react'

import { getToken } from '../../utils/auth'
import { getApiKey  } from '../../utils/config'

export default function ListQuiz({ quiz, user_id, course_id, studying_id, topic_id, setLink }){

	const [note, setNote] = useState();

	useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    }
		fetch(`${getApiKey()}/userQuiz/getByStudyingQuizId?studying_id=${studying_id}&quiz_id=${quiz.quiz_id}`, requestOptions)
		.then(response => response.json())
		.then(data => {
			if(data.error !== 10){
				setNote(data.nota)
			}
		})
	// eslint-disable-next-line
  }, [quiz])

	return(
		<div  className='card card-default'>
			<div className='card-header'>
				<h3 className='card-title'>{quiz.name}{note !== undefined && " - Sua maior nota desta prova: "+note.toFixed(1)}</h3>
				<div className='card-tools'>
				<button type="button" className='btn btn-tool' data-toggle="modal" data-target="#modal-default"
					onClick={() => {setLink(`/private/${user_id}/course/${course_id}/${studying_id}/topics/${topic_id}/quiz/${quiz.quiz_id}/${quiz.amountQuestion}`)}}>
						{note === undefined ? 'Começar agora' : 'Refazer'}
					</button>
				</div>
			</div>
		</div>
	)
}
