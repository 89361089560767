//React
import React, {useEffect, useState} from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
//Utils
import { isLogged, getToken } from '../../../utils/auth';
import { getApiKey } from '../../../utils/config';
//Component
const Students = () => {
	const { user_id } = useParams();
	const { userId } = useParams();
	const { courseName } = useParams();
	const { course_id } = useParams();

	const [users, setUsers] = useState();
	const [typeUsers, setTypeUsers] = useState('all');

	useEffect(() => {
		if(!!document.getElementById('studying')) document.getElementById('studying').classList.remove('active');
		if(!!document.getElementById('admins')) document.getElementById('admins').classList.remove("active");
		if(!!document.getElementById('teachers')) document.getElementById('teachers').classList.remove("active");
		if(!!document.getElementById('myCourses')) document.getElementById('myCourses').classList.add("active");
		if(!!document.getElementById('followers')) document.getElementById('followers').classList.remove("active");
		if(!!document.getElementById('following')) document.getElementById('following').classList.remove("active");
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}
		fetch(`${getApiKey()}/studying/getByCourseIdTypeUser/?course_id=${course_id}&type_user=${typeUsers}`, requestOptions)
		.then(response => { response.json().then(data => setUsers(data.users))})
  }, [user_id, userId, course_id, typeUsers])

	if(!isLogged()) return <Navigate  to="/"/>

	if(!users) return <> Loading... </>

	return(
		<div className="tab-pane">
			<div className="card-header">
				<h3 className="card-title">{courseName}</h3>

				<div className="card-tools">
					<div className="input-group input-group-sm" style={{width: "150px"}}>
						<select className="form-control float-right" defaultValue={typeUsers} required onChange={(e) => setTypeUsers(e.target.value)}>
							<option value="all">Todos</option>
							<option value="subAdmin">Administrador</option>
							<option value="teacher">Professor</option>
							<option value="student">Estudante</option>
						</select>
					</div>
				</div>
			</div>
			<div className="card-body p-0">

				<table className="table table-striped projects">
						<thead>
								<tr>
									<th style={{width: '40%'}}>
										Nome
									</th>
									<th>
									</th>
									<th style={{width: '60%'}}>
										Progresso
									</th>
								</tr>
						</thead>
						<tbody>
						{
							users.length > 0
							? users?.map((user, index) =>
									<tr key={index}>
										<td> <Link to={`/private/${user_id}/profile/${userId}/${courseName}/student/${course_id}/${user.user_id}/${user.name}`}>{user.name}</Link> </td>
										<td>
										</td>
										<td className='project_progress'>
											<div className="progress progress-sm">
												<div className="progress-bar bg-green" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: `${user.percent}%`}}>
												</div>
											</div>
											<small>{user.percent.toFixed()}% Complete</small>
										</td>
									</tr>
								)
							: <p> Não há Estudantes na lista! </p>
						}
						</tbody>
				</table>
			</div>
		</div>
	)
}

export default Students;
