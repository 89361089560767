//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//Utils
import { getApiKey  } from '../../utils/config';
//Component
const ForgotPassword = () => {

	const [email, setEmail] = useState('');

	function submitEmail(e){
		e.preventDefault()

		const headers = new Headers({
			"Content-Type": "application/json"
		});

		const body = {
			email: email
		};

		const requestOptions = {
			method: 'POST',
			headers: headers,
			mode: 'cors',
			body: JSON.stringify(body)
		}

		fetch(`${getApiKey()}/user/login/forgotPassword`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				alert("success", data)
				setTimeout(() => window.location.href = `/recoverPassword/${email}`, 1500)
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				alert("error", data)
				setTimeout(() => window.location.href = `/recoverPassword/${email}`, 1500)
			})
		})
	}

  return (
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <Link to="/"><img className="contaider_logo" src="https://audaces.com/wp-content/themes/Audaces-2018/img/logo.svg" alt="Logo Audaces"/></Link>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Você esqueceu sua senha? Aqui você pode facilmente recuperar uma nova senha.</p>
            <form onSubmit={(e) => submitEmail(e)}>
              <div className="input-group mb-3">
                <input type="email" className="form-control" required placeholder="Insira seu email" onChange={(e) => setEmail(e.target.value)}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type='submit' className="btn btn-primary btn-block"> enviar </button>
                </div>
              </div>
            </form>
            <p className="mt-3 mb-1">
              <Link to="/"> Login </Link>
            </p>
          </div>
        </div>
      </div>
  )
}

export default ForgotPassword;
