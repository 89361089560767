//React
import React, { useState, useEffect} from 'react';
//Utils
import { getToken } from '../../utils/auth';
import { getApiKey } from '../../utils/config';
//Layout
import SubHeader from '../../components/layout/SubHeader';
//Lists
import ListCourses from '../lists/ListCourses';

const Saved = () => {
  const [courses, setCourses] = useState()

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    }
    fetch(`${getApiKey()}/saved/getById`, requestOptions)
      .then(response => response.json())
      .then(data => setCourses(data.courses))
  }, [])

	if(!courses) return <>Loading ...</>

  return(
		<div className="content-wrapper">
			<SubHeader name={"Cursos Salvos ("+courses.length+")"} itens={[{name:'Home', path:'/private/home'}, {name:'Cursos'}]}/>
			<section className="content">
					<div className="card card-solid">
							<div className="card-body pb-0">
									<div className="row">
									{
										courses.length > 0 ?
											courses?.map((course, index) =>
												<div key={index} className='col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column'>
													<ListCourses course={course} />
												</div>
											)
										: <p> Não há curso na lista! </p>
									}
									</div>
							</div>
					</div>
			</section>
		</div>
  )
}

export default Saved;
