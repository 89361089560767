//React
import React from 'react'
import { useParams, Link, Outlet } from 'react-router-dom'
import SubHeader from '../layout/SubHeader'

const ProfileEdit = () => {
	const { user_id } = useParams(); 
	const { userId } = useParams();

	return(
		<div className="content-wrapper">
			<SubHeader name='Perfil' itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Perfil'}]}/>
			<section className="content">
				<div className="container-fluid">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-5 col-sm-3">
									<div className="nav flex-column nav-tabs h-100">
										<Link className="nav-link active" data-toggle="pill" to={`/private/${user_id}/profile/edit/${userId}`}>Dados Pessoais</Link>
										<Link className="nav-link" data-toggle="pill" to="">Habilidades</Link>
										<Link className="nav-link" data-toggle="pill" to="">Formação Acadêmica</Link>
										<Link className="nav-link" data-toggle="pill" to="#">Experiências</Link>
									</div>
								</div>
								<div className="col-7 col-sm-9">
									<div className="tab-content">
										<Outlet />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default ProfileEdit;
