//React
import React, { useState, useEffect }  from 'react'
import { Link } from 'react-router-dom'
//Utils
import { getToken } from '../../utils/auth'
import { getApiKey  } from '../../utils/config';
//Component
const SideBarCourse = ({ user_id, course_id, studying_id, topic_id, setTopics_id }) => {

	const [studingTopics, setStudingTopics] = useState();
	const [topics, setTopics] = useState();

	useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    }
		fetch(`${getApiKey()}/studingTopics/getByStudyingId/?studying_id=${studying_id}`, requestOptions)
		.then(response => response.json())
		.then(data => setStudingTopics(data.studying_topics))

    fetch(`${getApiKey()}/topics/get/?course_id=${course_id}`, requestOptions)
		.then(response => response.json())
		.then(data => setTopics(data.listTopics))
	// eslint-disable-next-line
  }, [])

	if(!studingTopics || !topics) return <> Loading... </>;

	return (
		<aside className="main-sidebar sidebar-dark-primary elevation-4">
			<Link to={`/private/${user_id}/home`} className="brand-link">
				<img src="https://s3.sa-east-1.amazonaws.com/ead.audaces.com/images/audaces-icon.png" alt="Audaces Logo" className="brand-image img-circle elevation-3" style={{opacity: .8}} />
				<span className="brand-text font-weight-light">Audamy</span>
			</Link>
			<div className="sidebar">
					<nav className="mt-2">
						<ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
						{
							topics?.map((topic, index) =>
								<li key={index} className='nav-item'>
									{
										( !!studingTopics[index].blocked )
										?
											<Link to='#' className={(topic.topics_id === topic_id) ? 'nav-link active' : 'nav-link'} style={{cursor: "not-allowed"}} >
												<i className="fas fa-lock"></i>
												<p className="ml-1">{topic.name}</p>
											</Link>
										:
											<Link to='#' onClick={() => setTopics_id(topic.topics_id)} className={(topic.topics_id === topic_id) ? 'nav-link active' : 'nav-link'} style={{cursor: "pointer"}} >
												<i className="fas fa-lock-open"></i>
												<p className="ml-1">{topic.name}</p>
											</Link>
									}
								</li>
							)
						}
						</ul>
					</nav>
			</div>
		</aside>
	)
}

export default SideBarCourse;
