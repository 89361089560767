import React, {useEffect, useState} from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';

import { isLogged, getToken } from '../../../utils/auth';
import { getApiKey } from '../../../utils/config';

const ListNotes = ({course_id, user_id, userId, topics_id, courseName}) => {

	const [notes, setNotes] = useState()

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}

		fetch(`${getApiKey()}/studying/getByUserChoices/?user_id=${userId}&course_id=${course_id}&topics_id=${topics_id}`, requestOptions).then(response => {
			response.json().then(data => setNotes(data.listNotes))
		})
  }, [course_id, userId, topics_id])

	function formatDate(dateString){
		const date = new Date(dateString)
		const dateFormat = date.getFullYear() +"-"+ date.getMonth()+1 +"-"+date.getDate()+" "+ date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
		return dateFormat;
	}

	return(
		<table className="table table-striped projects">
			<thead>
				<tr>
						<th style={{width: '20%'}}>
							Nome
						</th>
						<th style={{width: '20%'}}>
							Inicio
						</th>
						<th style={{width: '20%'}}>
							Fim
						</th>
						<th style={{width: '40%'}}>
							Nota
						</th>
				</tr>
			</thead>
			<tbody>
			{
				notes?.map((note, index) =>
						<tr key={index}>
							<td><Link to={`/private/${user_id}/profile/${courseName}/student/${course_id}/${userId}/${note.studying_id}/quizQuestions/${note.quiz_id}/${formatDate(note.dateStart)}`}>{note.name}</Link></td>
							<td>{formatDate(note.dateStart)}</td>
							<td>{formatDate(note.dateFinish)}</td>
							<td className='project_progress'>
								<div className="progress progress-sm">
									<div className="progress-bar bg-green" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: `${note.note}%`}}>
									</div>
								</div>
								<small>{note.note.toFixed()}% Complete</small>
							</td>
						</tr>
					)
				}
			</tbody>
		</table>
	)
}

const Student = () => {
	const { user_id } = useParams()
	const { userId } = useParams()
	const { courseName } = useParams()
	const { course_id } = useParams()
	const { name } = useParams()

	const [topics, setTopics] = useState()
	const [topics_id, setTopic_id] = useState()


	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}
		fetch(`${getApiKey()}/topics/getIdByCourse/?course_id=${course_id}`, requestOptions)
		.then(response => {
			response.json().then(data => setTopics(data.listTopics))
		})
  }, [course_id, userId])

	if(!isLogged()) return <Navigate  to="/" />

	if(!topics) return <>Loading...</>;

	return(
		<div className="tab-pane">
			<div className="card-header">
				<h3 className="card-title">{courseName} - {name}</h3>
			</div>
			<div className="card-body p-0">
				{
					!!topics_id ?
						<>
							<ul className="nav nav-tabs">
								{
									topics?.map((topic, index)=>
										<li key={index} className="nav-item "><Link to='#' className="nav-link" data-toggle="pill" onClick={()=>setTopic_id(topic.topics_id)}>{topic.name}</Link></li>
									)
								}
							</ul>
							<ListNotes course_id={course_id} user_id={user_id} userId={userId} topics_id={topics_id} courseName={courseName} />
						</>
					:
						<>
							<ul className="nav nav-tabs">
								{
									topics?.map((topic, index) =>
										index === 0 ?
											<li key={index} className="nav-item "><Link to='#' className="nav-link active" data-toggle="pill" onClick={()=>setTopic_id(topic.topics_id)}>{topic.name}</Link></li>
										:
											<li key={index} className="nav-item "><Link to='#' className="nav-link" data-toggle="pill" onClick={()=>setTopic_id(topic.topics_id)}>{topic.name}</Link></li>
									)
								}
							</ul>
							<ListNotes course_id={course_id} user_id={user_id} userId={userId} topics_id={topics[0].topics_id} courseName={courseName} />
						</>
				}

			</div>
		</div>
	)
}

export default Student;
// {
// 	!!topic_id ?
// 	<>
// 		<Header user_id={user_id}/>
// 		<SideBarCourse user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={topic_id} setTopics_id={setTopics_id} />
// 		<Course user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={topic_id} setLink={setLink} />
// 	</>
// 	:
// 	<>
// 		<Header user_id={user_id}/>
// 		<SideBarCourse user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={listIdTopics[0].topics_id} setTopics_id={setTopics_id} />
// 		<Course user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={listIdTopics[0].topics_id} setLink={setLink} />
// 	</>
