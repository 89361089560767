//React
import React, {useEffect, useState} from 'react';
import { useParams, Link, Navigate  } from 'react-router-dom';
//Utils
import { isLogged, getToken } from '../../../utils/auth';
import { getApiKey } from '../../../utils/config';
import { alert } from '../../../utils/alerts';
//component
const ListUser = () => {
	const { user_id } = useParams();
	const { userId } = useParams();
	const { type_user } = useParams();
	const { sector } = useParams();

	const [users, setUsers] = useState();
	const [isUpdate, setIsUpdate] = useState(false);

	const headers = new Headers({
		"Content-Type": "application/json",
		'x-access-token': getToken()
	});

	useEffect(() => {
		if(type_user === "subAdmin"){
			if(!!document.getElementById('studying')) document.getElementById('studying').classList.remove('active');
			if(!!document.getElementById('admins')) document.getElementById('admins').classList.add("active");
			if(!!document.getElementById('teachers')) document.getElementById('teachers').classList.remove("active");
			if(!!document.getElementById('myCourses')) document.getElementById('myCourses').classList.remove("active");
			if(!!document.getElementById('followers')) document.getElementById('followers').classList.remove("active");
			if(!!document.getElementById('following')) document.getElementById('following').classList.remove("active");
		} else if(type_user === "teacher"){
			if(!!document.getElementById('studying')) document.getElementById('studying').classList.remove('active');
			if(!!document.getElementById('admins')) document.getElementById('admins').classList.remove("active");
			if(!!document.getElementById('teachers')) document.getElementById('teachers').classList.add("active");
			if(!!document.getElementById('myCourses')) document.getElementById('myCourses').classList.remove("active");
			if(!!document.getElementById('followers')) document.getElementById('followers').classList.remove("active");
			if(!!document.getElementById('following')) document.getElementById('following').classList.remove("active");
		}
		const requestOptions = {
			method: 'GET',
			headers,
			mode: 'cors'
		}
		fetch(`${getApiKey()}/user/getByTypeUser/?typeUser=${type_user}&sector=${sector}`, requestOptions)
		.then( response => {
			response.json().then(data => setUsers(data.users))
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, userId, sector, isUpdate, type_user])

	function disableUser(user){
		const requestOptions = {
			method: 'PUT',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				user_id: user.user_id,
				status: !user.user_status
			})
		}
		fetch(`${getApiKey()}/user/changeStatus`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				alert("info", data.message);
				setIsUpdate(!isUpdate)
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				console.log(response)
				alert("error", data.message);
			}
			)
		})
	}

	if(!isLogged()) return <Navigate  to="/"/>;

	if(!users ) return <> Loading... </>;

	return(
      <div className="tab-pane">
        <div className="card-body p-0">
          <table className="table table-striped projects">
              <thead>
                  <tr>
                      <th style={{width: '25%'}}>
                        Nome
                      </th>
                      <th style={{width: '20%'}}>
                        Setor
                      </th>
                      <th style={{width: '10%'}}>
                        Status
                      </th>
                      <th style={{width: '40%', textAlign: 'right'}}>
												<Link to={`/private/${user_id}/addUser/${type_user}/${sector}`} className="btn btn-tool">Adicionar Professor</Link>
                      </th>
                  </tr>
              </thead>
              <tbody>
								{
									users?.map((user, index) =>
										<tr key={index}>
											<td> <Link to={`/private/${user_id}/profile/${user.user_id}/followers`} >{user.name}</Link> </td>
											<td> {user.sector} </td>
											<td> {user.user_status ? 'Ativo' : 'Inativo'} </td>
											<td className='project-actions text-right'>
												<Link to={`/private/${user_id}/profile/${user_id}/viewTeacher/${user.user_id}` } className='btn btn-primary btn-sm'> <i className='fas fa-book'> </i> View </Link>
												<Link to={`/private/${user_id}/editUser/${type_user}/${user.user_id}/${sector}` } className='btn btn-info btn-sm mx-2'> <i className='fas fa-pencil-alt'> </i> Editar </Link>
												{
													(!!user.user_status)
													? <button onClick={() => disableUser(user)} className='btn btn-danger btn-sm'> <i className="fas fa-times"></i> Desativar </button>
													: <button onClick={() => disableUser(user)} className='btn btn-success btn-sm'> <i className="fas fa-check"></i> Ativar </button>
												}
											</td>
										</tr>
									)
								}
							</tbody>
          </table>
        </div>
      </div>
	)
}

export default ListUser;
