//React
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
//utils
import { getToken } from '../../utils/auth';
import { getApiKey } from '../../utils/config';
import { alert } from '../../utils/alerts';
//layout
import SubHeader from '../layout/SubHeader'
//Component
const UserEditAdd = () => {

	const { user_id } = useParams();
	const { typeUser } = useParams();
	const { userId } = useParams();
	const { sector } = useParams();

	const [user, setUser] = useState()

	const headers = new Headers({
		"Content-Type": "application/json",
		'x-access-token': getToken()
	});

	useEffect(() => {
		if(!!userId){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				}
			}
			fetch(`${getApiKey()}/user/getById/?user_id=${userId}`, requestOptions)
			.then(response => {
				response.json()
				.then(data => {
					const user = data.user;
					const date = new Date(user.birth);
					const dateFormatada = (date.getFullYear() ) + "-" + ((date.getMonth() +1 < 9) ? "0" + (date.getMonth() +1 ) : date.getMonth() + 1 )  + "-" + date.getDate();
					setUser({
						user_id: user.user_id,
						name: user.name,
						sector: user.sector,
						number: user.number,
						email: user.email,
						birth: dateFormatada,
						gender: user.gender
					})
				})
			})
		} else {
			setUser({
				user_id: '',
				name: '',
				sector: (sector === 'all') ? 'administrative' : sector,
				number: '',
				email: '',
				birth: '',
				gender: 'feminine'
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

	function registerUser(e){
		e.preventDefault()

		const requestOptions = {
			method: 'POST',
			headers: headers,
			mode: 'cors',
			body: JSON.stringify({
				type_user: typeUser,
				name: user.name,
				sector: user.sector,
				number: user.number.replace("(", '').replace(")", '').replace(" ", ''),
				email: user.email,
				gender: user.gender,
				birth: user.birth
			})
		};

		fetch(`${getApiKey()}/user/create`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				if(data.code === 20){
					alert("info", data.message[0]);
				} else if(data.code === 10) {
					alert("error", data.message);
				} else {
					alert("success", data.message);
					setTimeout(()=>{
						window.location.href = `/private/${user_id}/profile/${user_id}/${typeUser}/${sector}`;
					}, 3000)
				}
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				if(data.code === 10){
					console.log(data.error);
					alert("error", data.message);
				}
			})
		})
	}

	function salvarUser(e){
		e.preventDefault()

		const requestOptions = {
			method: 'PUT',
			headers: headers,
			mode: 'cors',
			body: JSON.stringify({
				user: user
			})
		};

		fetch(`${getApiKey()}/user/update`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				alert("success", data.message);
				setTimeout(()=>{
					!!userId
					? window.location.href = `/private/${user_id}/profile/${user_id}/admins/all`
					: window.location.href = `/private/${user_id}/profile/${user_id}/admins/all`;
				}, 1500)
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				console.log(data);
			})
		})
	}

	const typePage = typeUser === 'subAdmin' ? 'Admin' : 'Professor';

	if(!user ) return <> Loading... </>

	return(
		<div className="hold-transition sidebar-mini">
			<div className="wrapper">
				<div className="content-wrapper">
					{
						!!userId
						? <SubHeader name={'Editar '+typePage} itens={[{name:'Home', path:`/private/${user_id}/home`}, {name:'Perfil', path:`/private/${user_id}/profile/${user_id}/${typeUser}/${sector}`}, {name:'Editar Admin'}]}/>
						: <SubHeader name={'Adicionar '+typePage} itens={[{name:'Home', path:'/private/home'}, {name:'Perfil', path:`/private/${user_id}/profile/${user_id}/${typeUser}/${sector}`}, {name:'Adicionar Admin'}]}/>
					}
					<form onSubmit={!userId ? (e) => registerUser(e) : (e) => salvarUser(e)} className="content">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Dados do {typePage}</h3>
							</div>
							<div className="card-body">
								<div className="form-group">
									<label>Nome</label>
									<input type="text" className="form-control" required value={user.name} onChange={(e) => setUser(prevState => { return { ...prevState, name: e.target.value }}) }/>
								</div>
								<div className="form-group">
									<label>Setor</label>
									<select className="form-control" value={user.sector} onChange={(e) => setUser(prevState => { return { ...prevState, sector: e.target.value }}) }>
										{ ( sector === 'all' || sector === 'administrative' ) && <option value="administrative">Administrativo</option> }
										{ ( sector === 'all' || sector === 'financial' ) && <option value="financial">Financeiro</option> }
										{ ( sector === 'all' || sector === 'humanResources' ) && <option value="humanResources">Recursos Humanos</option> }
										{ ( sector === 'all' || sector === 'commercial' ) && <option value="commercial">Setor Comercial</option> }
										{ ( sector === 'all' || sector === 'operating' ) && <option value="operating">Setor Operacional</option> }
										{ ( sector === 'all' || sector === 'informationTechnology' ) && <option value="informationTechnology">Tecnologia da Imformação</option> }
										{ ( sector === 'all' || sector === 'Development' ) && <option value="Development">Desenvolvimento</option> }
									</select>
								</div>
								<div className="form-group">
									<label>Celular</label>
									<InputMask type="text" className="form-control" required value={user.number} onChange={(e) => setUser(prevState => { return { ...prevState, number: e.target.value }})} mask="(99) 99999-9999" />
								</div>
								<div className="form-group">
									<label>E-mail</label>
									<input type="email" className="form-control" required value={user.email} onChange={(e) => setUser(prevState => { return { ...prevState, email: e.target.value }}) }/>
								</div>
								<div className="form-group">
									<label>Data de Nascimento</label>
									<input type="date" className="form-control" required value={user.birth} onChange={(e) => setUser(prevState => { return { ...prevState, birth: e.target.value }})} />
								</div>
								<div className="form-group">
									<label>Gênero</label>
									<select className="form-control" value={user.gender} onChange={(e) => setUser(prevState => { return { ...prevState, gender: e.target.value }})}>
										<option value="feminine">Feminino</option>
										<option value="masculine">Masculino</option>
										<option value="other">Outro</option>
									</select>
								</div>
								<button type="submit" className="btn btn-primary float-right"> {!userId ? 'Cadastrar' : 'Salvar'} </button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default UserEditAdd;
