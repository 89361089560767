import React, { useState, useEffect } from 'react'

import { getToken } from '../../../utils/auth'
import { getApiKey  } from '../../../utils/config'

const Question = ({ question, studying_id, quiz_id, shuffleArray, isDesable, setIsDesable, valueQuestion, note, setNote, dateNow }) => {

	const [isClick, setIsClick] = useState(true)
	const [choices, setChoices] = useState([])

	const headers = new Headers({
		'Content-Type': 'application/json',
		'x-access-token': getToken()
	});

	useEffect(() => {
		setChoices([])
    let requestOptions = {
			method: 'GET',
			headers,
			redirect: 'follow'
		}
		fetch(`${getApiKey()}/choice/getAllById?question_id=${question.question_id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setChoices(shuffleArray(result.listChoice))
			choices.forEach((element, index) => {
				if(!!document.getElementById(`input${index}`)) document.getElementById(`input${index}`).classList.remove("alert-success");
				if(!!document.getElementById(`input${index}`)) document.getElementById(`input${index}`).classList.remove("alert-danger");
				if(!!document.getElementById(`input${index}`)) document.getElementById(`input${index}`).classList.remove("btn-success");
				if(!!document.getElementById(`input${index}`)) document.getElementById(`input${index}`).classList.add("btn-default");
			});
			setIsClick(true)
		})
		.catch(error => console.log('error', error))
	// eslint-disable-next-line
  }, [question.question_id])

	function submitQuestion(index, choice){
		if(!isClick) return;

		setIsClick(!isClick)
		setIsDesable(!isDesable)

		if(choice.type){
			document.getElementById(`input${index}`).classList.remove("btn-default");
			document.getElementById(`input${index}`).classList.add("alert-success");
			setNote(note + valueQuestion)
		} else {
			document.getElementById(`input${index}`).classList.remove("btn-default");
			document.getElementById(`input${index}`).classList.add("alert-danger");
		}
		const requestOptions = {
			method: 'POST',
			headers,
			body: JSON.stringify({
				question: {
					studying_id: studying_id,
					choice_id: choice.choice_id,
					question_id: question.question_id,
					quiz_id: quiz_id,
					dateNow: dateNow
				}
			})
		}

		fetch(`${getApiKey()}/userQuiz/create`, requestOptions)
		.then(response => response.json())
		.catch(error => console.log('error', error))
	}

	if(!choices) return <>Loading ...</>

	return (
		<>
			<h5 className="mb-4">{question.description}</h5>
			<div className='form-group'>
				{
					choices?.map((choice, index) =>
						<div key={index} id={`input${index}`}
						onClick={() => submitQuestion(index, choice)}
						className={( !isClick & choice.type) ? "alert btn-success" :"alert btn-default"}
						style={{cursor: "pointer"}}>
							{choice.description}
						</div>
					)
				}
			</div>
		</>
	)
}

export default Question;
