//React
import React, { useEffect, useState } from 'react';
//utils
import { getToken  } from '../../../utils/auth';
import { getApiKey  } from '../../../utils/config';
import { alert } from '../../../utils/alerts';
//Component
const QuizData = ({ topic_id, quiz_id, setQuiz_id, listQuiz, setListQuiz, resetQuiz, lastQuiz, changeQuiz}) => {
	const [quiz, setQuiz] = useState();

	const headers = new Headers({
		'Content-Type': 'application/json',
		'x-access-token': getToken()
	});

	useEffect(() => {
		if(!!quiz_id){
			const requestOptions = {
				method: 'GET',
				headers,
				mode: 'cors',
			}
			fetch(`${getApiKey()}/quiz/getById/?quiz_id=${quiz_id}`, requestOptions).then(response => {
				response.json().then(data => setQuiz(data.quiz))
			})
		} else {
			setQuiz({
				name: '',
				amountQuestion: 0
			})
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quiz_id])

	function save(){
		const body = {
			quiz
		}
		const requestOptions = {
			method: 'PUT',
			headers,
			mode: 'cors',
			body: JSON.stringify(body)
		}
		fetch(`${getApiKey()}/quiz/update`, requestOptions)
		.then(response => {
			response.json()
			.then(data => {
				// let number;
				// listQuiz.forEach( (element, index) => {
				// 	if(element.quiz_id === quiz.quiz_id){
				// 		number = index
				// 	}
				// })
				// listQuiz[number].name = quiz.name;
				alert("success", data.message)
			})
		})
		.catch(response => {
			response.json()
			.then(data => {
				alert("error", data.message)
			})
		})
	}

	function add(){
		const body = {
			topic_id,
			quiz
		}
		const requestOptions = {
			method: 'POST',
			headers,
			mode: 'cors',
			body: JSON.stringify(body)
		}
		fetch(`${getApiKey()}/quiz/crete`, requestOptions)
		.then(response => {
			response.json()
			.then(data => {
				quiz.quiz_id = data.quiz_id;
				quiz.topic_id = topic_id;
				setListQuiz([...listQuiz, quiz]);
				setQuiz_id(data.quiz_id);
				changeQuiz("", 'quiz'+listQuiz.length);
				alert("success", data.message);
			})
		})
	}

	if(!quiz) return <>Loading...</>;

	return(
		<div className="card-body">
			<div className="form-group">
				<label>Nome da prova:</label>
				<input type="text" className="form-control" value={quiz.name} onChange={(e) => setQuiz(prevState => { return { ...prevState, name: e.target.value }})} />
			</div>
			<div className="form-group">
				<label>Quantidade de questões :</label>
				<input type="number" className="form-control" value={quiz.amountQuestion} onChange={(e) => setQuiz(prevState => { return { ...prevState, amountQuestion: e.target.value }})} />
			</div>
			{
				!!lastQuiz ?
					<>
						<button type="button"className="btn btn-primary float-left" onClick={() => resetQuiz()}> Nova Prova </button>
						<button type="button"className="btn btn-primary float-right" onClick={() => save()}> Salvar </button>
					</>
				:
					<button type="button"className="btn btn-primary float-right" onClick={() => add()}> Adcionar </button>
			}
		</div>
	)
}

export default QuizData;
