import React, { useState } from "react";
const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
	window.onscroll = function() {mudarCabecalho()};
	function mudarCabecalho() {
			let nVScroll = document.documentElement.scrollTop || document.body.scrollTop;
			if(nVScroll > 150){
				setIsVisible(true)
			} else {
				setIsVisible(false)
			}
	}
	function scrollToTop() {
		window.scrollTo(0, 0)
	}
	return(
			!!isVisible &&
			<button type="button" className="btn btn-primary back-to-top" onClick={()=>scrollToTop()}>
				<i className="fas fa-chevron-up"></i>
			</button>
	)
}
export default BackToTop;
