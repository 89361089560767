//React
import React, { useEffect, useState} from 'react';
import { Outlet, Navigate } from 'react-router-dom';
//Utils
import { getToken, isLogged } from '../utils/auth';
import { getApiKey  } from '../utils/config';
//layout
import Header from '../components/layout/Header';
import SideBar from '../components/layout/SideBar';
import Loading from '../components/layout/Loading';
import BackToTop from '../components/layout/BackToTop';
//Component
const PrivateRoute = () => {
  const [user, setUser] = useState();

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}
		fetch(`${getApiKey()}/user/getByToken`, requestOptions)
		.then( response => response.json().then(data => setUser(data))
		)
  }, [])

	if(!isLogged()) return <Navigate  to="/"/>

	if(!user ) return <Loading />

	return(
		<div className='wrapper'>
			<Header user_id={user.user_id} />
			<SideBar user_id={user.user_id} name={user.name} image={user.profilePicture} />
			<BackToTop />
			<Outlet />
		</div>
	)
}

export default PrivateRoute;
