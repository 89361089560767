//React
import React from 'react';
import { Link } from 'react-router-dom';
//Auth
import { logout} from '../../utils/auth';
//Component
const SideBar = ({ user_id, name, image }) => {
	function exit(){
		logout()
		window.location.href = "/"
	}
	return(
		<aside className="main-sidebar sidebar-dark-primary elevation-4">
		<Link to={`/private/${user_id}/home`} className="brand-link">
				<img src="https://s3.sa-east-1.amazonaws.com/ead.audaces.com/images/audaces-icon.png" alt="Audaces Logo" className="brand-image img-circle elevation-3" style={{opacity: .8}}/>
				<span className="brand-text font-weight-light">Audamy</span>
		</Link>
		<div className="sidebar">
				<div className="user-panel mt-3 pb-3 mb-3 d-flex">
						<div className="image">
								<img src={image} className="img-circle elevation-2" alt="User"/>
						</div>
						<div className="info">
								<Link to={`/private/${user_id}/profile/${user_id}`} className="d-block">{name}</Link>
						</div>
				</div>
				<nav className="mt-2">
						<ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
								<li className="nav-item">
										<Link to={`/private/${user_id}/home`} className="nav-link">
												<i className="fas fa-home"></i>
												<p className="ml-1">Home</p>
										</Link>
								</li>
								<li className="nav-item">
										<Link to={`/private/${user_id}/courses`} className="nav-link">
												<i className="fas fa-book"></i>
												<p className="ml-1">Cursos</p>
										</Link>
								</li>
								<li className="nav-item">
										<Link to="/private/saved" className="nav-link">
												<i className="far fa-bookmark"></i>
												<p className="ml-1">Salvos</p>
										</Link>
								</li>
								<li className="nav-item">
										<Link to="#" onClick={exit} className="nav-link">
												<i className="fas fa-door-open"></i>
												<p className="ml-1">Sair</p>
										</Link>
								</li>
						</ul>
				</nav>
		</div>
		</aside>
	)
}

export default SideBar;
