//React
import React, { useEffect, useState } from 'react';
//Utils
import { getToken  } from '../../../utils/auth';
import { getApiKey  } from '../../../utils/config';
import { alert } from '../../../utils/alerts';
//Component
const TopicData = ({course_id, topic_id, setTopic_id, topics, setTopics, resetTopic, lastTopic, changeTopic }) => {

	const [topic, setTopic] = useState();

	const [video, setVideo] = useState();
	const [pdf, setPdf] = useState();


	const headers = new Headers({
		'x-access-token': getToken()
	});

	useEffect(() => {
		if(!!topic_id){
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': getToken()
				}
			}
			fetch(`${getApiKey()}/topics/getById/?topic_id=${topic_id}`, requestOptions).then(response => {
				response.json().then(data => setTopic(data.topic))
			})
		} else {
			setTopic({
				name:'',
				description: '',
				link_video: '',
				link_pdf: '',
				text: ''
			})
			setVideo()
			setPdf()
		}
  }, [topic_id])

	function save(){
		let formdata = new FormData();
		if(!!video) formdata.append('video', video.files[0]);
		if(!!pdf) formdata.append('pdf', pdf.files[0]);
		formdata.append('topics_id', topic.topics_id);
		formdata.append('name', topic.name);
		formdata.append('description', topic.description);
		formdata.append('link_pdf', topic.link_pdf);
		formdata.append('text', topic.text);

		const requestOptions = {
			method: 'PUT',
			headers,
			mode: 'cors',
			body: formdata
		};
		fetch(`${getApiKey()}/topics/update`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				alert("success", data.message);
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				alert("error", data.message);
			})
		})
	}

	function add(){
		let formdata = new FormData();
		if(!!video) formdata.append('video', video.files[0]);
		if(!!pdf) formdata.append('pdf', pdf.files[0]);
		formdata.append('course_id', course_id);
		formdata.append('name', topic.name);
		formdata.append('description', topic.description);
		formdata.append('link_video', topic.link_video);
		formdata.append('link_pdf', topic.link_pdf);
		formdata.append('text', topic.text);
		const requestOptions = {
			method: 'POST',
			headers,
			mode: 'cors',
			body: formdata
		};
		fetch(`${getApiKey()}/topics/crete`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				if(data.code === 40){
					alert("error", data.message)
				} else {
					topic.topics_id = data.topic_id;
					setTopics([...topics, topic]);
					setTopic_id(data.topic_id);
					changeTopic("", 'topic'+topics.length)
					alert("success", data.message);
				}
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				console.log(data);
			})
		})
	}

	if(!topic) return <> Loading... </>

	return(
		<>
			<div className="form-group">
				<label> Nome Do tópico </label>
				<input type="text" className="form-control" value={topic.name} onChange={(e) => setTopic(prevState => { return { ...prevState, name: e.target.value }})} />
			</div>
			<div className="form-group">
				<label> Descrição </label>
				<input type="text" className="form-control" value={topic.description} onChange={(e) => setTopic(prevState => { return { ...prevState, description: e.target.value }})} />
			</div>
			<div className="form-group">
				<label> Video </label><br/>
				<input type="file" className='mb-2' onChange={(e) => setVideo(e.target)}/>
				<input type="text" className="form-control" disabled value={topic.link_video} />
			</div>
			<div className="form-group">
				<label> Link do pdf</label><br/>
				<input type="file" className='mb-2' onChange={(e) => setPdf(e.target)}/>
				<input type="text" className="form-control" disabled value={topic.link_pdf} />
			</div>
			<div className="form-group">
				<label> Texo da pagina</label>
				<textarea className="form-control" rows="3" value={topic.text} onChange={(e) => setTopic(prevState => { return { ...prevState, text: e.target.value }})}></textarea>
			</div>
			{
				!!lastTopic &&
				<>
					<button type="button"className="btn btn-primary float-left" onClick={() => resetTopic()}> Novo Topico </button>
					<button type="button"className="btn btn-primary float-right" onClick={() => save()}> Salvar </button>
				</>
			}{
				!lastTopic &&
				<>
					<button type="button"className="btn btn-primary float-right" onClick={() => add()}> Adcionar </button>
				</>
			}
		</>
	)
}

export default TopicData;
