//React
import React, { useEffect, useState } from 'react';
//utils
import { getToken  } from '../../../utils/auth';
import { getApiKey  } from '../../../utils/config';
import { alert } from '../../../utils/alerts';
//Component
const QuestionData = ({ quiz_id, question_id, setQuestion_id, questions, setQuestions, resetQuestion, lastQuestion, changeQuestion }) => {
	const [question, setQuestion] = useState();

	const headers = new Headers({
		'Content-Type': 'application/json',
		'x-access-token': getToken()
	});

	useEffect(() => {
		if(!!question_id){
			const requestOptions = {
				method: 'GET',
				headers,
				mode: 'cors',
			}
			fetch(`${getApiKey()}/question/getById/?question_id=${question_id}`, requestOptions)
			.then(response => {
				response.json().then(data => setQuestion(data.question))
			})
		} else {
			setQuestion({
				description:'',
				type: 'multipleChoice'
			})
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question_id])

	function save(){
		const requestOptions = {
			method: 'PUT',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				question
			})
		}
		fetch(`${getApiKey()}/question/update`, requestOptions)
		.then(response => {
			response.json()
			.then(data => {
				// let number;
				// questions.forEach( (element, index) => {
				// 	if(element.question_id === question.question_id){
				// 		number = index
				// 	}
				// })
				// questions[number].description = question.description;
				// questions[number].type = question.type;
				alert("success", data.message)
			})
		})
	}

	function add(){
		const requestOptions = {
			method: 'POST',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				quiz_id,
				question
			})
		}
		fetch(`${getApiKey()}/question/crete`, requestOptions)
		.then(response => {
			response.json()
			.then(data => {
				question.question_id = data.question_id;
				question.quiz_id = quiz_id;
				setQuestions([...questions, question]);
				setQuestion_id(data.question_id);
				changeQuestion("", 'question'+questions.length);
				alert("success", data.message);
			})
		})
		.catch( error => {
			error.json()
			.then( data => {
				alert("error", data.message)
			})
		})
	}

	if(!question) return <>Loading...</>

	return(
		<div className="card-body">
			<div className="form-group">
				<label>Questão:</label>
				<textarea className="form-control" rows="3" value={question.description} onChange={(e) => setQuestion(prevState => { return { ...prevState, description: e.target.value }})} />
				<label>Tipo de Questão:</label>
				<select className="form-control" value={question.type} onChange={(e) => setQuestion(prevState => { return { ...prevState, type: e.target.value }})}>
					<option value="multipleChoice">Multipla escolha</option>
					<option value="singleChoice">escolha unica</option>
				</select>
			</div>
			{
				!!lastQuestion ?
					<>
						<button type="button"className="btn btn-primary float-left" onClick={() => resetQuestion()}> Nova Questão </button>
						<button type="button"className="btn btn-primary float-right" onClick={() => save()}> Salvar </button>
					</>
				:
					<button type="button"className="btn btn-primary float-right" onClick={() => add()}> Adcionar </button>
			}
		</div>
	)
}

export default QuestionData;
