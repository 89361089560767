//React
import React, {useEffect, useState} from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
//Utils
import { isLogged, getToken  } from '../../../utils/auth';
import { getApiKey  } from '../../../utils/config';

const Followers = () => {
	const { user_id } = useParams();
	const { userId } = useParams();

	const [users, setUsers] = useState();
	const [isFollower, setIsFollower] = useState();

	const headers = new Headers({
		"Content-Type": "application/json",
		'x-access-token': getToken()
	})

	useEffect(() => {
		if(!!document.getElementById('studying')) document.getElementById('studying').classList.remove('active');
		if(!!document.getElementById('admins')) document.getElementById('admins').classList.remove("active");
		if(!!document.getElementById('teachers')) document.getElementById('teachers').classList.remove("active");
		if(!!document.getElementById('myCourses')) document.getElementById('myCourses').classList.remove("active");
		if(!!document.getElementById('followers')) document.getElementById('followers').classList.add("active");
		if(!!document.getElementById('following')) document.getElementById('following').classList.remove("active");
		const requestOptions = {
			method: 'GET',
			headers
		}
		fetch(`${getApiKey()}/follow/getUserFollower/?follower=${userId}`, requestOptions).then(response => {
			response.json()
			.then(data => {
				setUsers(data.followUsers)
			})
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, userId, isFollower])

	function follow(user){
		const requestOptions = {
			method: 'POST',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				follower: user_id,
				following: user.user_id
			})
		};
		fetch(`${getApiKey()}/follow/create`, requestOptions)
		.then( response => {
			setIsFollower(!isFollower)
		})
	}

	function unFollow(user){
		const requestOptions = {
			method: 'DELETE',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				follower: user_id,
				following: user.user_id
			})
		};
		fetch(`${getApiKey()}/follow/delete`, requestOptions)
		.then(response => {
			response.json()
			.then(data => {
				setIsFollower(data.follow)
			})
		})
		.catch(response =>{
			response.json()
			.then(data => {
				console.log(data);
			})
		})
	}

	if(!isLogged()) return <Navigate  to="/"/>

	if(!users ) return <>Loading...</>

	return(
		<div className="tab-pane">
			<div className="card-body p-0">
				<table className="table table-striped projects">
					<thead>
						<tr>
							<th style={{width: '40%'}}>
								Nome
							</th>
							<th>
							Setor
							</th>
							<th style={{width: '30%', textAlign: 'right'}}>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							users?.map((user, index) =>
								<tr key={index}>
									<td><Link to={`/private/${userId}/profile/${user.user_id}`}>{user.name}</Link></td>
									<td>{user.sector}</td>
									<td className='project-actions text-right'>
										{
											user_id === userId &&
												user.following
												? <button className='btn btn-danger btn-sm' onClick={() => unFollow(user)}> Deixar de seguir </button>
												: <button className='btn btn-success btn-sm' onClick={() => follow(user)}> Seguir </button>
										}
									</td>
								</tr>
							)
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Followers;
