import { Link } from 'react-router-dom'

export default function SubHeader({name, itens}){
	return(
		<section className="content-header">
			<div className="container-fluid">
				<div className="row mb-2">
					<div className="col-sm-6">
						<h1>{name}</h1>
					</div>
					<div className="col-sm-6">
						<ol className="breadcrumb float-sm-right">
						{
							itens?.map((item, index) =>
								!!item.path
								?<li key={index} className="breadcrumb-item"><Link to={item.path}>{item.name}</Link></li>
								: <li key={index} className="breadcrumb-item active">{item.name}</li>
							)
						}
						</ol>
					</div>
				</div>
			</div>
		</section>
	)
}

