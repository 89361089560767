//React
import React, {useEffect, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
//Utils
import { getToken  } from '../../../utils/auth';
import { getApiKey  } from '../../../utils/config';
//lists
import CourseItem from './itens/CourseItem';
//Component
const MyCourses = () => {
	const { user_id } = useParams();
	const { userId } = useParams();

	const [listCourse, setListCourse] = useState();

	useEffect(() => {
		if(!!document.getElementById('studying')) document.getElementById('studying').classList.remove('active');
		if(!!document.getElementById('admins')) document.getElementById('admins').classList.remove("active");
		if(!!document.getElementById('teachers')) document.getElementById('teachers').classList.remove("active");
		if(!!document.getElementById('myCourses')) document.getElementById('myCourses').classList.add("active");
		if(!!document.getElementById('followers')) document.getElementById('followers').classList.remove("active");
		if(!!document.getElementById('following')) document.getElementById('following').classList.remove("active");
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}
		fetch(`${getApiKey()}/course/getByUser`, requestOptions)
		.then(response => {
			response.json().then(data => setListCourse(data.courses))
		})
  }, [user_id, userId])

	if( !listCourse ) return <> Loading... </>

	return(
		<div className="tab-pane">
		<div className="card-body p-0">
			<table className="table table-striped projects">
				<thead>
					<tr>
						<th style={{width: '30%'}}>
							Nome
						</th>
						<th style={{width: '25%'}}>
							Atualização
						</th>
						<th style={{width: '40%', textAlign: 'right'}}>
							<Link to={`/private/${user_id}/addCourse`} className="btn btn-tool">Adicionar Cursos</Link>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						listCourse?.map((course, index) =>
							<CourseItem key={index} userId={user_id} course={course} />
						)
					}
				</tbody>
			</table>
		</div>
		</div>
	)
}

export default MyCourses;
