//React
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import InputMask from 'react-input-mask';
//utils
import { getToken  } from '../../../../utils/auth';
import { getApiKey } from '../../../../utils/config';
//Component
const PersonalData = () => {
	const { user_id } = useParams()

	const [isUpdate, setIsUpdate] = useState(true);

	const [image, setImage] = useState();
	const [imageUrl, setImageUrl] = useState();
	const [name, setName] = useState('');
	const [number, setNumber] = useState('');
	const [email, setEmail] = useState('');
	const [birth, setBirth] = useState();
	const [gender, setGender] = useState('feminine');
	const [biography, setBiography] = useState('');
	const [about, setAbout] = useState('');
	const [linkedin, setLinkedin] = useState();
	const [github, setGithub] = useState('');

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}
		fetch(`${getApiKey()}/user/getById/?user_id=${user_id}`, requestOptions)
		.then(response => {
			response.json()
			.then(data => {
				setImageUrl(data.user.profilePicture);
				setName(data.user.name);
				setNumber(data.user.number);
				setEmail(data.user.email);
				const date = new Date(data.user.birth);
				setBirth(((date.getFullYear() )) + "-" + ((date.getMonth() +1 < 9) ? "0" + (date.getMonth() +1 ) : date.getMonth() + 1 )  + "-" + date.getDate());
				setGender(data.user.gender);
				(!!data.user.biography) ? setBiography(data.user.biography) : setBiography('');
				(!!data.user.about) ? setAbout(data.user.about) : setAbout('');
				(!!data.user.linkedin) ? setLinkedin(data.user.linkedin) : setLinkedin('');
				(!!data.user.github) ? setGithub(data.user.github) : setGithub('');
			})
		})
  }, [user_id, isUpdate])

	function saveUser(e){
		e.preventDefault();

		const headers = new Headers({
			'x-access-token': getToken()
		});

		let formdata = new FormData();
		!!image ? formdata.append('image', image.files[0]) : formdata.append('image', imageUrl);
		formdata.append('user_id', user_id);
		formdata.append('name', name);
		formdata.append('number', number);
		formdata.append('email', email);
		formdata.append('birth', birth);
		formdata.append('gender', gender);
		formdata.append('biography', biography);
		formdata.append('about', about);
		formdata.append('linkedin', linkedin);
		formdata.append('github', github);

		const requestOptions = {
			method: 'PUT',
			headers: headers,
			mode: 'cors',
			body: formdata
		};

		fetch(`${getApiKey()}/user/updateUser`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				setIsUpdate(!isUpdate)
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				console.log(data);
			})
		})
	}

	return (
		<form onSubmit={(e) => saveUser(e)} className="tab-pane text-left fade show active" role="tabpanel" aria-labelledby="vert-tabs-home-tab">
			<div className="form-group">
				<img className="profile-user-img img-fluid img-circle m-2 " src={imageUrl} alt="User profile" />
				<input type="file" onChange={(e) => setImage(e.target)}/>
			</div>
			<div className="form-group">
				<label>Nome</label>
				<input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)}/>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label>E-mail</label>
						<input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
					</div>
					<div className="form-group">
						<label>Data de Nascimento</label>
						<input type="date" className="form-control" value={birth} onChange={(e) => setBirth(e.target.value)}/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
						<label>Celular</label>
						<InputMask type="text" name="phone" className="form-control" mask="(99) 99999-9999" value={number} onChange={(e) => setNumber(e.target.value)}/>
					</div>
					<div className="form-group">
						<label>Gênero</label>
						<select className="form-control" value={gender} onChange={(e) => setGender(e.target.value)}>
							<option value="feminine">Feminino</option>
							<option value="masculine">Masculino</option>
							<option value="other">Outro</option>
						</select>
					</div>
				</div>
			</div>
			<div className="form-group">
				<label>Biografia</label>
				<input type="text" className="form-control" value={biography} onChange={(e) => setBiography(e.target.value)}/>
			</div>
			<div className="form-group">
				<label>Sobre você</label>
				<textarea rows="4" className="form-control" value={about} onChange={(e) => setAbout(e.target.value)}/>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label>Linkedin</label>
						<input type="text" className="form-control" value={linkedin} onChange={(e) => setLinkedin(e.target.value)}/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
						<label>Github</label>
						<input type="text" className="form-control" value={github} onChange={(e) => setGithub(e.target.value)}/>
					</div>
				</div>
			</div>
			<button type="submit" className="btn btn-primary float-right"> Salvar </button>
		</form>
	)
}

export default PersonalData;
