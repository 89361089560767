import React from 'react'
import { Link } from 'react-router-dom'

const ListStudying = ({ user_id, course }) => {
	return (
		<tr>
      <td><Link to={`/private/${user_id}/course/${course.course_id}`}>{course.name}</Link></td>
      <td className="project_progress">
				<div className="progress progress-sm">
					<div className="progress-bar bg-green" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: `${course.percentage}%`}}>
					</div>
				</div>
				<small>{course.percentage}% Complete</small>
			</td>
      <td className='project-actions text-right'>
        <Link to='#' className='btn btn-danger btn-sm'> <i className='fas fa-trash'> </i> trancar </Link>
      </td>
    </tr>
	)
}

export default ListStudying
