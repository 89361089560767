//React
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
//Lists
import ListStudying from '../../lists/ListStudying';
//Utils
import { getToken  } from '../../../utils/auth';
import { getApiKey  } from '../../../utils/config';
//Component
const Studying = () => {

	const { user_id } = useParams();
	const { userId } = useParams();

	const [courses, setCourses] = useState();

  useEffect(() => {
		if(!!document.getElementById('studying')) document.getElementById('studying').classList.add('active');
		if(!!document.getElementById('admins')) document.getElementById('admins').classList.remove("active");
		if(!!document.getElementById('teachers')) document.getElementById('teachers').classList.remove("active");
		if(!!document.getElementById('myCourses')) document.getElementById('myCourses').classList.remove("active");
		if(!!document.getElementById('followers')) document.getElementById('followers').classList.remove("active");
		if(!!document.getElementById('following')) document.getElementById('following').classList.remove("active");
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    }
    fetch(`${getApiKey()}/studying/getByUserId?user_id=${user_id}`, requestOptions)
		.then(response => response.json())
		.then(data => setCourses(data.courses))
  }, [user_id, userId])

	if(!courses ) return <> Loading... </>

	return(
      <div className="tab-pane">
        <div className="card-body p-0">
          <table className="table table-striped projects">
              <thead>
                  <tr>
                      <th style={{width: '40%'}}>
                        Nome
                      </th>
                      <th>
                        Progresso
                      </th>
                      <th style={{width: '30%', textAlign: 'right'}}>
                      </th>
                  </tr>
              </thead>
              <tbody>
								{
									courses?.map((course, index) =>
											<ListStudying key={index} user_id={user_id} course={course} />
									)
								}
							</tbody>
          </table>
        </div>
      </div>
	)
}

export default Studying;
