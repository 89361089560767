//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
//Utils
import { getApiKey  } from '../../utils/config';
import { alert  } from '../../utils/alerts';
//Component
const Register = () => {

	const [name, setName] = useState('');
	const [number, setNumber] = useState('');
	const [email, setEmail] = useState('');
	const [birth, setBirth] = useState();
	const [gender, setGender] = useState();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [checked, setChecked] = useState('');


	const register = (e) => {
		e.preventDefault()

		if(!name || !number || !email || !gender || !password ) return alert("warning", "Preencha todos os campos!");

		if(!checked) return alert("warning", "Você precisa concordar com os termos de uso!");

		const headers = new Headers({
			"Content-Type": "application/json"
		});

		const requestOptions = {
			method: 'POST',
			headers: headers,
			mode: 'cors',
			body: JSON.stringify({
				type_user: "student",
				name: name,
				sector: "all",
				number: number.replace("(", '').replace(")", '').replace(" ", ''),
				gender: gender,
				birth: birth,
				email: email,
				password: password,
				confirmPassword: confirmPassword
			})
		}

		fetch(`${getApiKey()}/user/register`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				if(data.code === 10){
						alert("error", data.message);
				} else if(data.code === 20){
					alert("error", data.message[0]);
				} else {
					alert("success", data.message);
					setTimeout(() => window.location.href = `/authenticateEmail/${email}`, 1500)
				}
			})
		})
		.catch( response => {
			response.json()
			.then( data => {
				alert("error", data.message);
			})
		})
	}

	return (
		<div className="card card-outline card-primary">
				<div className="card-header text-center">
						<Link to="/"><img className="contaider_logo" src="https://audaces.com/wp-content/themes/Audaces-2018/img/logo.svg" alt="Logo Audaces"/></Link>
				</div>
				<div className="card-body">
						<p className="login-box-msg"> Cadastro de Cliente </p>
						<form onSubmit={register}>
								<div className="input-group mb-3">
										<input type="text" className="form-control" required placeholder="Nome Completo" onChange={(e) => setName(e.target.value)}/>
										<div className="input-group-append">
												<div className="input-group-text">
														<span className="fas fa-user"></span>
												</div>
										</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="input-group mb-3">
											<input type="date" className="form-control" required placeholder="Data de Nascimento" onChange={(e) => setBirth(e.target.value)}/>
											<div className="input-group-append">
													<div className="input-group-text">
															<span className="far fa-calendar-alt"></span>
													</div>
											</div>
										</div>
										<div className="input-group mb-3">
											<input type="email" className="form-control" required placeholder="E-mail" onChange={(e) => setEmail(e.target.value)}/>
											<div className="input-group-append">
													<div className="input-group-text">
															<span className="fas fa-envelope"></span>
													</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-group mb-3">
											<select className="form-control" defaultValue='gender' required onChange={(e) => setGender(e.target.value)}>
                    		<option value="gender" disabled >Genero</option>
												<option value="feminine">Feminino</option>
												<option value="masculine">Masculino</option>
												<option value="other">Outro</option>
											</select>
											<div className="input-group-append">
												<div className="input-group-text">
													<span className="fas fa-venus-mars"></span>
												</div>
											</div>
										</div>
										<div className="input-group mb-3">
											<InputMask type="tel" name="phone" className="form-control" required placeholder="Celular" onChange={(e) => setNumber(e.target.value)} mask="(99) 99999-9999" />
											<div className="input-group-append">
													<div className="input-group-text">
															<span className="fas fa-phone-alt"></span>
													</div>
											</div>
										</div>
									</div>
								</div>
								<div className="input-group mb-3">
										<input type="password" className="form-control" required placeholder="Senha" onChange={(e) => setPassword(e.target.value)}/>
										<div className="input-group-append">
												<div className="input-group-text">
														<span className="fas fa-lock"></span>
												</div>
										</div>
								</div>
								<div className="input-group mb-3">
										<input type="password" className="form-control" required placeholder="Confirmar Senha" onChange={(e) => setConfirmPassword(e.target.value)}/>
										<div className="input-group-append">
												<div className="input-group-text">
														<span className="fas fa-lock"></span>
												</div>
										</div>
								</div>
								<div className="row">
										<div className="col-8">
												<div className="icheck-primary">
														<input type="checkbox" id="agreeTerms" name="terms" defaultChecked={checked} onChange={() => setChecked(!checked)} />
														<label htmlFor="agreeTerms">
																Concordo com os <Link to="/">termos</Link>
														</label>
												</div>
										</div>
										<div className="col-4">
												<button type="submit" className="btn btn-primary btn-block"> Cadastrar </button>
										</div>
								</div>
						</form>
						<Link to="/" className="text-center"> Login </Link>
				</div>
		</div>
	)
}
export default Register;
