import React from "react";
import { Link } from 'react-router-dom';

const User = ({ user_id, user }) => {
	return(
		<div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
			<div className="card bg-light d-flex flex-fill">
				<div className="card-header text-muted border-bottom-0">{user.sector}</div>
				<div className="card-body pt-0">
					<div className="row">
						<div className="col-7">
							<h2 className="lead"><b>{user.name}</b></h2>
							<p className="text-muted text-sm"><b>About: </b> Web Designer / UX / Graphic Artist / Coffee Lover </p>
							<ul className="ml-4 mb-0 fa-ul text-muted">
								<li className="small"><span className="fa-li"><i className="fas fa-lg fa-building"></i></span> Address: Demo Street 123, Demo City 04312, NJ</li>
								<li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone"></i></span> Phone #: + 800 - 12 12 23 52</li>
							</ul>
						</div>
						<div className="col-5 text-center">
							<img src={user.profilePicture} alt="user-avatar" className="img-circle img-fluid" />
						</div>
					</div>
				</div>
				<div className="card-footer">
					<div className="text-right">
						<Link to={`/private/${user_id}/profile/${user.user_id}`} className="btn btn-sm btn-primary"> <i className="fas fa-user"></i> Vizualizar Perfil </Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default User;
