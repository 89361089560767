import React, { useEffect, useState } from 'react';
import { getToken } from '../../../utils/auth';
import { getApiKey } from '../../../utils/config';
import { alert } from '../../../utils/alerts';

const CourseData = ({ user_id, course, setCourse, isNewTech }) => {
	const [technologies, setTechnologies] = useState()

	const headers = new Headers({
		'Content-Type': 'application/json',
		'x-access-token': getToken()
	});

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: headers
		}
		fetch(`${getApiKey()}/technology/get`, requestOptions).then(response => {
			response.json().then(data => setTechnologies(data.technologies))
		})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewTech])

	function save(){
		const requestOptions = {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify({
				course
			})
		}
		fetch(`${getApiKey()}/course/update`, requestOptions)
		.then( response => {
			response.json()
			.then( data => {
				alert("success", data.message);
				setTimeout(()=>{
					window.location.href = `/private/${user_id}/editCourse/${course.course_id}/topics`;
				}, 3000)
			})
		})
		.catch( response => {
			response.json()
			.then( data => alert("error", data.message))
		})
	}

	function add(){
		const requestOptions = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				course
			})
		}
		fetch(`${getApiKey()}/course/create`, requestOptions)
		.then(response => {
			response.json().then( data => {
				alert("success", data.message);
				setCourse(prevState => { return { ...prevState, course_id: data.course_id }})
				setTimeout(()=>{
					window.location.href = `/private/${user_id}/editCourse/${course.course_id}/topics`;
				}, 3000)
			})
		})
		.catch( response => {
			response.json()
			.then( data => alert("error", data.message))
		})
	}

	return(
		<form className="card-body">
			<div className="form-group">
				<label>Nome do Curso</label>
				<input type="text" className="form-control" required value={course.name} onChange={(e) => setCourse(prevState =>  ({ ...prevState, name: e.target.value }))}/>
			</div>
			<div className="form-group">
				<label>Descrição</label>
				<textarea className="form-control" rows="3" required value={course.description} onChange={(e) => setCourse(prevState => { return { ...prevState, description: e.target.value }}) }></textarea>
			</div>
			<div className="form-group">
				<label>Nivel do Curso</label>
				<select className="form-control custom-select" required value={course.level} onChange={(e) => setCourse(prevState => { return { ...prevState, level: e.target.value }}) }>
					<option value="basic">Basico</option>
					<option value="intermediary">Intermediário</option>
					<option value="advanced">Avançado</option>
				</select>
			</div>
			<div className="form-group">
				<label>Tema Utilizado<button type="button" required className="btn btn-primary mx-3 float-right"  data-toggle="modal" data-target="#modal-default">
					Adicionar novo tema</button> </label>
				<select className="form-control custom-select" required value={course.technology} onChange={(e) => setCourse(prevState => { return { ...prevState, technology: e.target.value }}) }>
					{technologies?.map((technology, index) =>
						<option key={index} value={technology.technology_id}>{technology.name}</option>
					)}
				</select>
			</div>
			<div className="form-group">
				<label>Horas de Conclusão</label>
				<input type="number" className="form-control" required minLength={0} value={course.time} onChange={(e) => setCourse(prevState => { return { ...prevState, time: e.target.value }}) }/>
			</div>
			{
				!!course.course_id ?
					<button type="button" className="btn btn-primary float-right" onClick={()=>save()} >Salvar</button >
				:
					<button type="button" className="btn btn-primary float-right" onClick={()=>add()} >Adicionar</button >
			}
		</form>
	)
}
export default CourseData;
