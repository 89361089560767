import React, {useEffect, useState} from 'react'
import { useParams, Navigate  } from 'react-router-dom'

import { isLogged, getToken  } from '../../utils/auth'
import { getApiKey  } from '../../utils/config'

//lists
import CourseTeacher from './profile/itens/CourseItem'

const ViemTeacher = () => {
	const { user_id } = useParams()
	const { teacher_id } = useParams()

	const [user, setUser] = useState()
	const [courses, setCourses] = useState()

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}

		fetch(getApiKey()+'/user/getById/?user_id='+teacher_id, requestOptions).then(response => {
			response.json().then(data => setUser(data.name))
		})
		fetch(getApiKey()+'/course/getByUserId/?user_id='+teacher_id, requestOptions).then(response => {
			response.json().then(data => setCourses(data.courses))
		})
  }, [teacher_id])

	if(!isLogged()) return <Navigate  to="/"/>

	if(!courses) return <> Loading... </>

	return(
		<div className="tab-pane">
        <div className="card-body p-0">
          <table className="table table-striped projects">
							<thead>
                  <tr>
                      <th style={{width: '30%'}}>
                        {user}
                      </th>
                      <th style={{width: '25%'}}>
                      </th>
                      <th style={{width: '40%', textAlign: 'right'}}>
												Total de Cursos: {courses.length}
                      </th>
                  </tr>
              </thead>
              <thead>
                  <tr>
                      <th style={{width: '30%'}}>
                        Nome
                      </th>
                      <th style={{width: '25%'}}>
												Atualização
                      </th>
                      <th style={{width: '40%', textAlign: 'right'}}>
                      </th>
                  </tr>
              </thead>
              <tbody>
							{
								courses?.map((course, index) =>
									<CourseTeacher key={index} userId={user_id} course={course} />
								)
							}
							</tbody>
          </table>
        </div>
      </div>
	)
}

export default ViemTeacher;
