import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { isLogged, getToken } from '../utils/auth';
import { getApiKey  } from '../utils/config';


import { ModalCourse } from '../components/layout/Modal';

//layout
import Loading from '../components/layout/Loading';
import Header from '../components/layout/Header';
import SideBarCourse from '../components/layout/SideBarCourse';

import Course from '../components/privatePages/Course';

const PrivateRoute = () => {
	const { user_id } = useParams();
	const { course_id } = useParams();

	const [studying, setStudying] = useState();
	const [topic_id, setTopics_id] = useState(0);
	const [listIdTopics, setListIdTopics] = useState();
	const [link, setLink] = useState('');

	useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    }
		fetch(`${getApiKey()}/studying/getByUserIdCourseId?user_id=${user_id}&course_id=${course_id}`, requestOptions)
		.then(response => response.json())
		.then(data => setStudying(data.studying[0]))

		// await fetch(`${getApiKey()}/studingTopics/getByStudyingId?studying_id=${studying.studying_id}`, requestOptions)
		// .then(response => response.json())
		// .then(data => 	setListIdTopics(data.studying))

    fetch(`${getApiKey()}/topics/getIdByCourse?course_id=${course_id}`, requestOptions)
		.then(response => response.json())
		.then(data => setListIdTopics(data.listTopics))

	// eslint-disable-next-line
  }, [course_id])

	if(!isLogged()) return <Navigate  to="/"/>;

	if(!studying) return <Loading />;

	if(!listIdTopics ) return <Loading />;

	return (
		<>
			<ModalCourse link={link} />
			{
				!!topic_id
				? <>
						<Header user_id={user_id}/>
						<SideBarCourse user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={topic_id} setTopics_id={setTopics_id} />
						<Course user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={topic_id} setLink={setLink} />
					</>
				: <>
						<Header user_id={user_id}/>
						<SideBarCourse user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={listIdTopics[0].topics_id} setTopics_id={setTopics_id} />
						<Course user_id={user_id} course_id={course_id} studying_id={studying.studying_id} topic_id={listIdTopics[0].topics_id} setLink={setLink} />
					</>
			}
		</>
	)
}

export default PrivateRoute
