import React, { useState, useEffect } from 'react'

import { getToken } from '../../utils/auth'
import { getApiKey  } from '../../utils/config'

import ListQuiz from '../lists/ListQuiz';


const Course = ({ user_id, course_id, studying_id, topic_id, setLink }) => {

	const [topic, setTopic] = useState()
	const [listQuiz, setListQuiz] = useState();

	useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': getToken()
      }
    }

    fetch(`${getApiKey()}/topics/getById/?topic_id=${topic_id}`, requestOptions)
		.then(response => response.json())
		.then(data => setTopic(data.topic))

    fetch(`${getApiKey()}/quiz/getAllById?topics_id=${topic_id}`, requestOptions)
		.then(response => response.json())
		.then(data => setListQuiz(data.listQuiz))
	// eslint-disable-next-line
  }, [topic_id])

	if(!topic) return <>Loading ...</>

	return (
		<div className="content-wrapper">
			<section className="content">
				<div className="container-fluid">
					<div className='embed-responsive embed-responsive-16by9 mb-3'>
						<iframe className='embed-responsive-item' src={topic.link_video} allowFullScreen title="Video do curso" />
					</div>
					{
						!!topic.link_pdf &&
						<div className='card card-default'>
							<div className='card-header'>
								<h3 className='card-title'> Conteudo em pdf </h3>
								<div className='card-tools'>
									<a href={topic.link_pdf} className='btn btn-tool'> ViSualizar </a>
								</div>
							</div>
						</div>
					}
					{
						listQuiz?.map((quiz, index) =>
							<ListQuiz key={index} quiz={quiz} user_id={user_id} course_id={course_id} studying_id={studying_id} topic_id={topic_id} setLink={setLink} />
						)
					}
				</div>
			</section>
		</div>
	)
}

export default Course
