import React from "react";
import { Donut } from 'react-dial-knob';

export default function Finished({ note }){

	const newNote = note * 10;
	return(
		<>
			<h3 className="mb-4">{(note >= 6 ) ? 'Parabens, você passou no teste' : 'Sinto muito, você deve repetir o teste'}</h3>
			<div className='row d-flex justify-content-center'>
				<Donut
					diameter={200}
					min={0}
					max={100}
					step={1}
					value={newNote}
					theme={{
							donutColor: (note >= 6 ) ? 'green' : 'red'
					}}
					ariaLabelledBy={'my-label'}
					spaceMaxFromZero={false}
				/>
			</div>
		</>
	)
}
