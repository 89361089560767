//React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//Auth
import { getToken } from '../../utils/auth';
import { getApiKey  } from '../../utils/config';
//Component
const ListCourses = ({ course, user_id }) => {
	const navigate = useNavigate();
	const [isStart, setIsStart] = useState(false);

	const headers = new Headers({
		"Content-Type": "application/json",
		'x-access-token': getToken()
	})

	useEffect(() => {
		if(isStart){
			const requestOptions = {
				mode: 'cors',
				headers,
				body: JSON.stringify({
					"course_id": course.course_id
				})
			}
			if (!course.saved){
				requestOptions.method= "POST";
				fetch(`${getApiKey()}/saved/create`, requestOptions)
			} else {
				requestOptions.method= "DELETE";
				fetch(`${getApiKey()}/saved/delete`, requestOptions)
			}
			setIsStart(false)
			course.saved = !course.saved
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isStart, course])

	function studying(){
		const requestOptions = {
			method: 'POST',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				course_id: course.course_id
			})
		};
		fetch(`${getApiKey()}/studying/create`, requestOptions)
		.then(response => response.json())
    	.then(data => {
			if(data.message === 'Sucesso'){
				navigate(`/private/${user_id}/course/${course.course_id}`);
			}
		})
	}

	return (
		<div className='card bg-light d-flex flex-fill'>
				<div className='card-header'>
						<h3 className='card-title'> {course.name} </h3>
						<div className='card-tools'>
							<button onClick={() => setIsStart(true)} type='button' className='btn btn-tool'>
								<i className={!!course.saved ? 'fas fa-bookmark': 'far fa-bookmark'}></i>
							</button>
					</div>
				</div>
				<div className='card-body pt-0'>
						<p>{course.description}</p>
				</div>
				<div className='card-footer'>
						<div className='float-left'><i className='fas fa-signal'></i>{course.level}</div>
						<div className='float-right'><i className='far fa-clock'></i>{course.time} hrs</div>
				</div>
				<div className='card-footer'>
						<button onClick={() => studying()} className='btn btn-primary btn-block'> Começar Agora </button>
				</div>
		</div>
	)
}

export default ListCourses;
