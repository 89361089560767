//React
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//Utils
import { login, setId } from '../../utils/auth';
import { getApiKey } from '../../utils/config';
import { alert } from '../../utils/alerts';
//Component
const Login = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState();
  const [password, setPassword] = useState();

	async function authenticate(e){
		e.preventDefault()
		const requestOptions = {
			method: 'POST',
			headers: new Headers({
				"Content-Type": "application/json"
			}),
			mode: 'cors',
			redirect: 'follow',
			body: JSON.stringify({
				email: email,
				password: password
			})
		}
		const response = await fetch(`${getApiKey()}/user/login`, requestOptions)
		const data = await response.json();
		console.log(data);
		if (data.code === 5){
			alert("error", data.message);
			setTimeout(() => navigate(`/recoverPassword/${email}`), 3000);
		} else if(data.code === 10){
			alert("error", data.message);
		} else if (data.code === 20){
			alert("success", data.message);
			setTimeout(() => navigate(`/authenticateEmail/${email}`), 3000);
		} else if (data.code === 30){
			login(data.token);
			setId(data.user_id);
			alert("success", data.message);
			setTimeout(() => navigate(`/private/${data.user_id}/home`), 3000);
		}
		// .then( response => response.json())
		// .then( data => {
		// 	console.log(data);
		// 	if (data.code === 5){
		// 		alert("error", data.message);
		// 		setTimeout(() => window.location.href = `/recoverPassword/${email}`, 3000);
		// 	} else if(data.code === 10){
		// 		alert("error", data.message);
		// 	} else if (data.code === 20){
		// 		alert("success", data.message);
		// 		setTimeout(() => window.location.href = `/authenticateEmail/${email}`, 3000);
		// 	} else if (data.code === 30){
		// 		login(data.token);
		// 		setId(data.user_id);
		// 		alert("success", data.message);
		// 		setTimeout(() => window.location.href = `/private/${data.user_id}/home`, 3000);
		// 	}
		// })
		// .catch( response => {
		// 	response.json()
		// 	.then( data => {
		// 		alert("error", data.message)
		// 	})
		// })
	}

	return (
		<div className="login-box">
			<div className="card card-outline card-primary">
				<div className="card-header text-center">
					<Link to="/" ><img className="contaider_logo" src="https://audaces.com/wp-content/themes/Audaces-2018/img/logo.svg" alt="Logo Audaces"/></Link>
				</div>
				<div className="card-body">
						<p className="login-box-msg"> Faça login para iniciar sua sessão </p>
						<form onSubmit={authenticate}>
								<div className="input-group mb-3">
										<input type="email" className="form-control" required placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
										<div className="input-group-append">
												<div className="input-group-text">
														<span className="fas fa-envelope"></span>
												</div>
										</div>
								</div>
								<div className="input-group mb-3">
										<input type="password" className="form-control" required placeholder="Password"  onChange={(e) => setPassword(e.target.value)} />
										<div className="input-group-append">
												<div className="input-group-text">
														<span className="fas fa-lock"></span>
												</div>
										</div>
								</div>
								<div className="row">
										<div className="col-8">
												<div className="icheck-primary">
														<input type="checkbox" id="remember"/>
														<label htmlFor="remember"> Lembrar da senha </label>
												</div>
										</div>
										<div className="col-4">
												<button type="submit" className="btn btn-primary btn-block"> Entrar </button>
										</div>
								</div>
						</form>
						<p className="mb-1">
								<Link to="/forgotPassword"> Esqueci a minha senha </Link>
						</p>
						<p className="mb-0">
								<Link to="/register" className="text-center"> Registre uma nova assinatura </Link>
						</p>
				</div>
			</div>
		</div>
	)
}

export default Login;
