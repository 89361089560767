import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

//elements
import Question from '../elements/quiz/Question';
import Finished from '../elements/quiz/Finished';

import { getToken } from '../../utils/auth';
import { getApiKey  } from '../../utils/config';

export default function Quiz(){

	const { user_id } = useParams();
	const { course_id } = useParams();
	const { topic_id } = useParams();
	const { studying_id } = useParams();
	const { quiz_id } = useParams();
	const { amount } = useParams();

	const [dateNow, setDateNow] = useState();

	const [isDesable, setIsDesable] = useState(true);
	const [number, setNumber] = useState(0);
	const [questions, setQuestions] = useState();

	let valueQuestion = 0;

	const [note, setNote] = useState(0);
	const [finished, setFinished] = useState(0);

	const headers = new Headers({
		'Content-Type': 'application/json',
		'x-access-token': getToken()
	});

	useEffect(() => {
		const date = new Date()
    const dateFormat = date.getFullYear() +"-"+ date.getMonth()+1 +"-"+date.getDate()+" "+ date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()
		setDateNow(dateFormat);
    const requestOptions = {
			method: 'GET',
			headers,
			redirect: 'follow'
		}

		fetch(`${getApiKey()}/question/getAllById?quiz_id=${quiz_id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setQuestions(amountArray(shuffleArray(result.listQuestion)))
		})
		.catch(error => console.log('error', error))
		// eslint-disable-next-line
  }, [quiz_id])

	function shuffleArray(arr) {
    // Loop em todos os elementos
    for (let i = arr.length - 1; i > 0; i--) {
        // Escolhendo elemento aleatório
        const j = Math.floor(Math.random() * (i + 1));
        // Reposicionando elemento
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
		// Retornando array com aleatoriedade
		return arr;
	}

	function amountArray(arr) {
		let newArray = [];
    for (let i = 0; i < amount; i++) {
			newArray.push(arr[i])
    }
		return newArray;
	}

	function nextQuestion(){
		if(!isDesable) {
			setNumber(number + 1)
			setIsDesable(!isDesable)
		}
	}

	function finish(){
		setFinished(1)
		if(note >= 6){
			const requestOptions = {
				method: 'put',
				headers,
				body: JSON.stringify({
					studying_id: studying_id,
					topic_id: topic_id,
					finished: 1
				})
			}
			fetch(`${getApiKey()}/studingTopics/updateFinish`, requestOptions)
			.then(response => response.json())
			.catch(error => console.log('error', error))
		}
	}

	if(!questions) return <>Loading ...</>

	valueQuestion += ( 10 / questions.length);

	return(
		<div className="login-page">
			<div className="content">
				<div className="container-fluid">
					<div className="row">
									<div className="col-md-12">
							<div className="card card-default">
								<div className="card-header">
									<h3 className="card-title">Questionario</h3>
								</div>
								{
									!finished ?
										<>
											<div className="card-body">
												<Question question={questions[number]} studying_id={studying_id} quiz_id={quiz_id} shuffleArray={shuffleArray} isDesable={isDesable} setIsDesable={setIsDesable} valueQuestion={valueQuestion} note={note} setNote={setNote} dateNow={dateNow} />
											</div>
											<div className="card-footer">
												<div className="float-right">
													{
														number < (questions.length - 1) ?
															<button type="button" onClick={() => nextQuestion()} className={isDesable ? 'btn btn-block btn-secondary disabled' : 'btn btn-block btn-warning' }>PRÓXIMA PERGUNTA</button>
														:
															<button type="button" onClick={() => finish()} className={isDesable ? 'btn btn-block btn-secondary disabled' : 'btn btn-block btn-primary' }>FINALIZAR</button>
													}
												</div>
											</div>
										</>
									:
										<>
											<div className="card-body">
												<Finished note={note}/>
											</div>
											<div className="card-footer">
												<div className="float-right">
														<Link to={`/private/${user_id}/course/${course_id}`} type="button" className='btn btn-block btn-primary'>VOLTAR PARA O CURSO </Link>
												</div>
											</div>
										</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
