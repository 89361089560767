//React
import React, { useEffect, useState } from 'react';
//utils
import { getToken  } from '../../../utils/auth';
import { getApiKey  } from '../../../utils/config';
import { alert  } from '../../../utils/alerts';
//Component
const ChoiceData = ({question_id, choice_id, choices, setChoices, resetChoice, lastChoice}) => {
	const [choice, setChoice] = useState();

	const headers = new Headers({
		'Content-Type': 'application/json',
		'x-access-token': getToken()
	});

	useEffect(() => {
		if(!!choice_id){
			const requestOptions = {
				method: 'GET',
				headers,
				mode: 'cors',
			}
			fetch(`${getApiKey()}/choice/getById/?choice_id=${choice_id}`, requestOptions)
			.then(response => {
				response.json().then(data => setChoice(data.choice))
			})
		} else {
			setChoice({
				description:'',
				type: 1
			})
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choice_id])

	function save(){
		const requestOptions = {
			method: 'PUT',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				choice
			})
		}
		fetch(`${getApiKey()}/choice/update`, requestOptions)
		.then(response => {
			response.json()
			.then( data => {
				let index = choices.findIndex(x => x.choice_id === choice.choice_id);
				choices[index].description = choice.description;
				choices[index].type = choice.type;
				alert("success", data.message);
				resetChoice()
			})
		})
		.catch( error => {
			error.json()
			.then( data => {
				alert("error", data.message)
			})
		})
	}

	function add(){
		const requestOptions = {
			method: 'POST',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				question_id,
				choice
			})
		}
		fetch(`${getApiKey()}/choice/crete`, requestOptions)
		.then(response => {
			response.json()
			.then(data => {
				choice.choice_id = data.choice_id;
				choice.question_id = question_id;
				setChoices([...choices, choice]);
				setChoice({
					description:'',
					type:''
				});
				alert("success", data.message);
			})
		})
		.catch( error => {
			error.json()
			.then( data => {
				alert("error", data.message)
			})
		})
	}

	function del(){
		const requestOptions = {
			method: 'DELETE',
			headers,
			mode: 'cors',
			body: JSON.stringify({
				choice_id: choice_id
			})
		}
		fetch(`${getApiKey()}/choice/delete`, requestOptions)
		.then(response => {
			response.json()
			.then(() => {
				let index = choices.findIndex(x => x.choice_id === choice.choice_id);
				resetChoice();
				delete choices[index];
			})
		})
	}

	if(!choice) return <>Loading...</>

	return(
		<div className="card-body">
			<div className="form-group">
				<label>Alternativa:</label>
				<textarea className="form-control" rows="3" value={choice.description} onChange={(e) => setChoice(prevState => { return { ...prevState, description: e.target.value }})} />
				<label>Tipo de Alternativa:</label>
				<div className="form-group">
					<div className='icheck-primary' >
						<input id="radioPrimary1" type='radio' name="type" value="true" checked={(choice.type === 1 | choice.type === "true") ? true : false} onChange={(e) => setChoice(prevState => { return { ...prevState, type: e.target.value }})}/>
						<label htmlFor="radioPrimary1" >Verdadeira</label>
					</div>
					<div className='icheck-primary'>
						<input id="radioPrimary2" type='radio' name="type" value="false" checked={(choice.type === 0 | choice.type === "false") ? true : false} onChange={(e) => setChoice(prevState => { return { ...prevState, type: e.target.value }})}/>
						<label htmlFor="radioPrimary2" >Falsa</label>
					</div>
				</div>
			</div>
			{
				!!lastChoice ?
					<>
						<button type="button" className="btn btn-primary float-left" onClick={() => resetChoice()}> Nova Questão </button>
						<button type="button" className="btn btn-primary float-right" onClick={() => save()}> Salvar </button>
						<button type="button" className='btn btn-danger mx-2 float-right' onClick={() => del()}><i className='fas fa-trash'></i> Delete </button>
					</>
				:
					<button type="button"className="btn btn-primary float-right" onClick={() => add()}> Adcionar </button>
			}
		</div>
	)
}

export default ChoiceData;
