import React, {useEffect, useState} from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { isLogged, getToken } from '../../../utils/auth';
import { getApiKey } from '../../../utils/config';

const QuizQuestions = () => {
	const { studying_id } = useParams()
	const { quiz_id } = useParams()
	const { dateStart } = useParams()

	const [questions, setQuestions] = useState()

	useEffect(() => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': getToken()
			}
		}

		fetch(`${getApiKey()}/userQuiz/getByDateQuizStudyingId/?studying_id=${studying_id}&quiz_id=${quiz_id}&dateStart=${dateStart}`, requestOptions)
		.then(response => {
			response.json().then(data => setQuestions(data.quizQuestions))
		})
  }, [studying_id, quiz_id, dateStart])

	if(!isLogged()) return <Navigate  to="/" />

	if(!questions || questions.length === 0) return <> Loading... </>

	return(
		<table className="table table-striped projects">
			<thead>
				<tr>
						<th style={{width: '85%'}}>
							Alternativa Escolhida
						</th>
						<th style={{width: '15%'}}>
							Status
						</th>
				</tr>
			</thead>
			<tbody>
				{
				questions?.map((question, index) =>
						<tr key={index}>
							<td>{question.description}</td>
							<td className="project-state">
							{
								(question.type)
								? <span className="badge badge-success">Verdadeiro</span>
								: <span className="badge badge-danger">Falso</span>}
							</td>
						</tr>
					)
					}
			</tbody>
	</table>
	)
}

export default QuizQuestions;
