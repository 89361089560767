import React from 'react';
import loading from '../../assets/gif/loading.gif'

export default function Loading(){
	return(
		<div className="text-center" style={{height: "100vh", background: "#0481dd"}}>
			<img src={loading} alt="Logo" style={
				{position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					margin: 'auto',
			}} />
		</div>
	)
}
